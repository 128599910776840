export const hubble = {
  id: "hubble",
  title: "Hubble Telescope",
  launchDate: "April 24, 1990",
  isFavorite: true,
  endDate: " Active",
  src: "/images/hubble.png",
  credit: "credit image",
  button: "Hubble",
  missionStatus: "active",
  missionType: "orbital",
  launchVehicle: "Space Shuttle Discovery ",
  launchSite: "Kennedy Space Center, Fl",
  keyFact: [
    "Orbits about 350 miles above Earth’s surface",
    "First telescope designed to be repaired in space",
  ],
  desc: {
    descId: "hubbleInfo",
    headline:
      "Hubble has technology that let astronomers study the heavens in different ways. Hubble can be partnered with other space observatories and those on the ground to enable scientists to explore the universe in ways that no single mission could ever accomplish alone",
    mainDesc:
      " The first major optical telescope to be placed in space, Hubble has been operational for more than 30 years. It has a crystal-clear view of the universe and has been used to observe objects as close as the moon to some of the most distant stars and galaxies yet seen.  It can view ultraviolet through the visible and into the near-infrared. New scientific instruments have been added to the telescope over the course of five astronaut servicing missions. Hubble has made more than 1.5 million observations over the course of its lifetime. ",
    subDesc: "", // " It has tracked interstellar objects as they soared through our solar system, watched a comet collide with Jupiter, and discovered moons around Pluto. It has found dusty disks and stellar nurseries throughout the Milky Way that may one day become fully fledged planetary systems and studied the atmospheres of planets that orbit other stars. Hubble has peered back into our universe’s distant past, to locations more than 13.4 billion light-years from Earth, capturing galaxies merging, probing the supermassive black holes that lurk in their depths, and helping us better understand the history of the expanding universe.",
  },
  images: [
    {
      imageId: "hubbleA",
      image: "/imageList/hubble/HubblefrEGGs.png",
      imageCredit:
        "NASA, ESA, and R. Sahai (Jet Propulsion Laboratory); Processing: Gladys Kober (NASA/Catholic University of America)",
      imageDesc: "Free-floating Evaporating Gaseous Globules (frEGGs)",
    },
    {
      imageId: "hubbleB",
      image: "/imageList/hubble/Hubblemrk1337.png",
      imageCredit: "ESA/Hubble & NASA, A. Riess et al",
      imageDesc:
        "Spiral galaxy Mrk (Markarian) 1337, which is roughly 120 million light-years away from Earth in the constellation Virgo",
    },
    {
      imageId: "hubbleC",
      image: "/imageList/hubble/HubbleNGC3568.png",
      imageCredit: "ESA/Hubble & NASA, M. Sun",
      imageDesc:
        "NGC 3568, a barred spiral galaxy roughly 57 million light-years from the Milky Way in the constellation Centaurus.",
    },
    {
      imageId: "hubbleO",
      image: "/imageList/hubble/crab.png",
      imageCredit: "NASA, ESA, and STScI",
      imageDesc:
        "The Southern Crab Nebula officially named Hen 2-104 southern hemisphere constellation of Centaurus",
    },
    {
      imageId: "hubbleD",
      image: "/imageList/hubble/HubblePillars.png",
      imageCredit: "ESA/Hubble",
      imageDesc: "Eagle Nebula’s Pillars of Creation",
    },
    {
      imageId: "hubbleE",
      image: "/imageList/hubble/HubbleUGC11537.png",
      imageCredit: "ESA/Hubble & NASA, A. Seth",
      imageDesc:
        "Spiral galaxy UGC 11537 230 million light-years away in the constellation Aquila",
    },
    {
      imageId: "hubbleF",
      image: "/imageList/hubble/abell78.png",
      imageCredit:
        "ESA/Hubble & NASA, M. Guerrero; Acknowledgment: Judy Schmidt",
      imageDesc:
        "Located around 5,000 light-years away in the constellation of Cygnus (the Swan), Abell 78 is an unusual type of planetary nebula.",
    },
    {
      imageId: "hubbleN",
      image: "/imageList/hubble/butterfly.png",
      imageCredit: "NASA, ESA, and J. Kastner (RIT)",
      imageDesc:
        "NGC 6302, known as the Butterfly Nebula located between 2,500 and 3,800 light-years away in the constellation Scorpius",
    },
    {
      imageId: "hubbleG",
      image: "/imageList/hubble/jupiter.png",
      imageCredit:
        "      NASA, ESA, A. Simon (Goddard Space Flight Center), and M. H. Wong (University of California, Berkeley) and the OPAL team ",
      imageDesc: "Image of Jupiter, also features Jupiter’s icy moon Europa",
    },
    {
      imageId: "hubbleH",
      image: "/imageList/hubble/spiral.png",
      imageCredit: "ESA / Hubble / L. Ho / J. Lee / PHANGS-HST Team ",
      imageDesc:
        " Spiral galaxy NGC 2903 located approximately 30 million light-years away in the constellation of Leo",
    },
    {
      imageId: "hubbleI",
      image: "/imageList/hubble/dyingStar.png",
      imageCredit: "ESA/Hubble & NASA, R. Wade et al ",
      imageDesc: " Star known as NGC 2371/2 dying in deep space",
    },
    {
      imageId: "hubbleJ",
      image: "/imageList/hubble/elGordo.png",
      imageCredit: "ESA/Hubble & NASA, RELICS",
      imageDesc:
        " Located more than 7 billion light-years from Earth, the El Gordo galaxy cluster has the mass of 3 million billion suns",
    },
    {
      imageId: "hubbleK",
      image: "/imageList/hubble/helix.png",
      imageCredit: "NASA, ESA, and C.R O'Dell(Vanderbilt University",
      imageDesc: " Helix Nebula in the constellation Aquarius",
    },
    {
      imageId: "hubbleQ",
      image: "/imageList/hubble/glitter.png",
      imageCredit: "NASA, ESA, and C.R O'Dell(Vanderbilt University",
      imageDesc:
        "Glittering star cluster in our Milky Way galaxy called Trumpler 14",
    },
    {
      imageId: "hubbleL",
      image: "/imageList/hubble/eXtremeDeep.png",
      imageCredit:
        " NASA; ESA; G. Illingworth, D. Magee, and P. Oesch, University of California, Santa Cruz; R. Bouwens, Leiden University; and the HUDF09 Team",
      imageDesc:
        "eXtreme Deep Field, or XDF, created by combining 10 years of NASA Hubble Space Telescope photographs taken of a patch of sky at the center of the original Hubble Ultra Deep Field.",
    },
    {
      imageId: "hubbleM",
      image: "/imageList/hubble/hubbleDeep.png",
      imageCredit: "NASA",
      imageDesc:
        "Hubble Ultra Deep Field is an image of a small area of space in the constellation Fornax, created using Hubble Space Telescope data from 2003 and 2004. Roughly 3,000 distant galaxies.",
    },
    {
      imageId: "hubbleP",
      image: "/imageList/hubble/bubble.png",
      imageCredit: "NASA, ESA, and the Hubble Heritage Team (STScI/AURA)",
      imageDesc: "Bubble Nebula, or NGC 7635",
    },
  ],
  recentNews: [
    {
      newsId: "hubNeb",
      image: "/imageList/hubble/hubNews.png",
      imageCredit:
        "Ray Villard, Space Telescope Science Institute, Baltimore, Maryland",
      month: "1",
      year: "2022",
      title: "3-D model of Great Eruption",
      summary:
        "A new astronomical visualization from NASA's Universe of Learning showcases the multiwavelength emissions (from infrared light through X-rays) and three-dimensional structures surrounding Eta Carinae, one of the most massive and eruptive stars in our galaxy. The video, 'Eta Carinae: The Great Eruption of a Massive Star', has been released on hubblesite.org and universe-of-learning.org. 'Spitzer's infrared image lets us peer through the dust that obscures our view in visible light to reveal the intricate details and extent of the Carina Nebula around this brilliant star,' commented Robert Hurt, lead visualization scientist at Caltech/IPAC and team member.",
      link: "https://hubblesite.org/contents/news-releases/2022/news-2022-004",
    },
    {
      newsId: "hubFar",
      image: "/imageList/hubble/farthestStar.png",
      imageCredit:
        "NASA/ESA/ALYSSA PAGAN (STSCI)",
      month: "3",
      year: "2022",
      title: "Hubble spots farthest star",
      summary:
        "Earendel’s discovery offers a glimpse into the first billion years after the Big Bang, when the universe was just 7 percent of its current age. At 12.9 billion light-years away, it smashes the previous record of 9 billion, which was also set by Hubble when it observed a giant blue star called Icarus in 2018.",
      link: "https://www.wired.com/story/earendel-farthest-star-nasa-hubble/",
    },

    {
      newsId: "hubMilestone",
      image: "/imageList/hubble/hubbleMilestone.png",
      imageCredit:
        "NASA, ESA, STScI",
      month: "6",
      year: "2022",
      title: "Hubble reaches new milestone",
        summary:"Completing a nearly 30-year marathon, NASA's Hubble Space Telescope has calibrated more than 40 'milepost markers' of space and time to help scientists precisely measure the expansion rate of the universe",
      link: "https://hubblesite.org/contents/news-releases/2022/news-2022-005",
    },



  ],
};
