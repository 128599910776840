import CameraIcon from "@mui/icons-material/Camera";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import { Button, CardContent, Divider, Grid } from "@mui/material";
import { useState } from "react";
import ReactBnbGallery from "react-bnb-gallery";
import "react-bnb-gallery/dist/style.css";

import { useRecoilValue } from "recoil";
import { StatusBox } from "../../App/StatusBox";
import {
  StyledListActionArea,
  StyledListCardHeadline,
  StyledTelescopeListCard,
  StyledTelescopeListCardMedia,
} from "../../App/styles/StyledTelescopeGridCard";
import { getAllFilteredTelescopesInfo } from "../../data/telescope";
import { statusLabel } from "../../Styles/styles";
import { TelescopeInfoGrid } from "../Telescope/TelescopeInfoGrid";

export const ListView = () => {
  const telescopes = useRecoilValue(getAllFilteredTelescopesInfo);
  const [imageList, setImageList] = useState([]);

  const telescopeImages = imageList || [];

  return (
    <>
      <Grid container p={3}>
        {telescopes.map((item, index) => (
          <>
            <StyledTelescopeListCard elevation={0}>
              <StyledTelescopeListCardMedia
                component="img"
                key={item.src}
                image={item.src}
                alt={item.title}
                title={item.credit}
              />
              <CardContent sx={{ flex: "1 0 auto" }}>
                <TelescopeInfoGrid
                  variant="h4"
                  title={item.title}
                  launch={item.launchDate}
                  headline={
                    <StyledListCardHeadline variant="subtitle1">
                      {item.desc.headline}
                    </StyledListCardHeadline>
                  }
                  button={
                    <StyledListActionArea>
                      {!!item.images?.length && (
                        <Button
                          endIcon={<ImageSearchIcon sx={{ mb: 2 }} />}
                          variant="text"
                          onClick={() => setImageList(item.images)}
                        >
                          View Images
                        </Button>
                      )}
                      <Button
                        endIcon={<CameraIcon sx={{ mb: 2 }} />}
                        variant="text"
                        href={`/telescope/${item.id}`}
                      >
                        Explore
                      </Button>
                    </StyledListActionArea>
                  }
                >
                  <StatusBox
                    item={item}
                    statusLabel={statusLabel}
                    variant="h6"
                  />
                </TelescopeInfoGrid>
              </CardContent>
            </StyledTelescopeListCard>

            <Grid container alignSelf="center" justifyContent="center">
              <Grid item sm={12}>
                {index !== telescopes.length - 1 ? (
                  <Divider textAlign="center" />
                ) : null}
              </Grid>
            </Grid>
          </>
        ))}
      </Grid>
      <ReactBnbGallery
        show={!!telescopeImages.length}
        activePhotoIndex={0}
        onClose={() => setImageList([])}
        preloadSize={8}
        photos={telescopeImages.map((image) => ({
          photo: image.image,
          caption: image.imageDesc,
          subcaption: image.imageCredit,
        }))}
      />
    </>
  );
};
