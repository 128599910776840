import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

export const FormCheckboxes = ({ name, onChange, checked, label }) => {
  return (
    <FormGroup row>
      <FormControlLabel
        key={name}
        control={
          <Checkbox
            type="checkbox"
            name={name}
            onChange={onChange}
            checked={checked}
          />
        }
        label={label}
      />
    </FormGroup>
  );
};
