export const newHorizons = {
  id: "newHorizon",
  title: "New Horizons",
  launchDate: "January 19, 2006",
  endDate: " Active",
  src: "/images/newHorizon.png",
  credit: "credit image",
  button: "New Horizons",
  missionStatus: "active",
  missionType: "flyby",
  launchVehicle: "Atlas V 551 ",
  launchSite: "Cape Canaveral, Fla.",
  keyFact: [
    "First spacecraft to explore Pluto and its Moons up close",
    "By the time it reached the Pluto system, the spacecraft had traveled farther away and for a longer time period (more than nine years) than any previous deep space spacecraft ever launched",
    "Discovered the size of Pluto is about 1,470 miles (2,370 kilometers) in diameter",
  ],

  desc: {
    descId: "horizonInfo",

    headline:
      "First spacecraft to explore a second Kuiper Belt Object up close",
    mainDesc:
      "New Horizons is the first mission in NASA's New Frontiers mission category.  The goal of the mission study the dwarf planet Pluto, its moons, and other objects in the Kuiper Belt, and the transformation of the early Solar System.  On July 14, 2015 it flew above the surface of Pluto making it the first spacecraft to explore the dwarf planet. ",
    subDesc:
      "It collected data on the atmospheres, surfaces, interiors, and environments of Pluto and its moons. It also studies other objects in the Kuiper belt.  New Horizon confirmed the existence of a 'hydrogen wall' at the outer edges of the Solar System  first detected in 1992 by the two Voyager spacecraft. It is the fifth space probe to achieve the escape velocity needed to leave the Solar System.",
  },

  images: [
    {
      imageId: "newHorizonA",
      image: "/imageList/newHorizon/colorGlobalPluto.png",
      imageCredit: "NASA/JHUAPL/SwRI",
      imageDesc: " An enhanced color global view of Pluto",
    },
    {
      imageId: "newHorizonB",
      image: "/imageList/newHorizon/plutoHaze.png",
      imageCredit: "NASA/JHUAPL/SwRI",
      imageDesc:
        "Shown in approximate true color, a look at the haze layers in Pluto’s atmosphere. ",
    },
    {
      imageId: "newHorizonC",
      image: "/imageList/newHorizon/jupiterMoons.png",
      imageCredit: "NASA/JHU-APL/Southwest Research Institute",
      imageDesc: " Io, Europa, Ganymede and Callisto",
    },
    {
      imageId: "newHorizonD",
      image: "/imageList/newHorizon/plutoSun.png",
      imageCredit: " NASA/JHUAPL/SwRI",
      imageDesc:
        "Sunlight filters through and illuminates Pluto’s atmospheric haze layers",
    },
    {
      imageId: "newHorizonE",
      image: "/imageList/newHorizon/plutoRidges.png",
      imageCredit: "NASA",
      imageDesc: "Evenly spaced ridges on Pluto’s surface.",
    },
    {
      imageId: "newHorizonF",
      image: "/imageList/newHorizon/jupiterandIo.png",
      imageCredit: "NASA, JHU-APL, Southwest Research Institute",
      imageDesc: "Jupiter and the Jovian moon Io",
    },
    {
      imageId: "newHorizonG",
      image: "/imageList/newHorizon/plutoHazeLayers.png",
      imageCredit: "NASA/JHUAPL/SwRI",
      imageDesc: "About 20 haze layers above Pluto",
    },
    {
      imageId: "newHorizonH",
      image: "/imageList/newHorizon/plutoLayerCrater.png",
      imageCredit: "NASA/JHUAPL/SwRI",
      imageDesc: "Pluto's cratered plains",
    },
    {
      imageId: "newHorizonI",
      image: "/imageList/newHorizon/plutoCanyonSystem.png",
      imageCredit: "NASA/JHUAPL/SwRI",
      imageDesc:
        "Part of a great canyon system that stretches for hundreds of miles across Pluto’s northern hemisphere",
    },
    {
      imageId: "newHorizonJ",
      image: "/imageList/newHorizon/plutoCrescent.png",
      imageCredit: "NASA/JHUAPL/SwRI",
      imageDesc: "Pluto’s crescent",
    },
    {
      imageId: "newHorizonK",
      image: "/imageList/newHorizon/plutoRugged.png",
      imageCredit: "NASA/JHUAPL/SwRI",
      imageDesc:
        "Krun Macula (the prominent dark spot at the bottom of the image) and the complex terrain of Pluto",
    },
    {
      imageId: "newHorizonL",
      image: "/imageList/newHorizon/plutoFog.png",
      imageCredit: "NASA/JHUAPL/SwRI",
      imageDesc:
        "Setting sun illuminates a fog or near-surface haze, and shadows of many local hills and small mountains",
    },
    {
      imageId: "newHorizonM",
      image: "/imageList/newHorizon/plutoCharon.png",
      imageCredit: "NASA/JHUAPL/SwRI",
      imageDesc: "Enhanced color image of Pluto and Charon",
    },
    {
      imageId: "newHorizonN",
      image: "/imageList/newHorizon/jupiterMoonStorm.png",
      imageCredit:
        "NASA/Johns Hopkins University Applied Physics Laboratory/Southwest Research Institute",
      imageDesc:
        "Moons Io (bottom) and Ganymede pictured right; Ganymede's shadow moving toward the top of Jupiter.  Two of Jupiter's largest storms are visible. The Great Red Spot (left) is a 300-year old storm more than twice the size of Earth. The Little Red Spot (right), formed over the past decade from the merging of three smaller storms.",
    },

    {
      imageId: "newHorizonO",
      image: "/imageList/newHorizon/plutoDetail.png",
      imageCredit: "NASA/APL/SwRI",
      imageDesc: "Detailed image of Pluto",
    },
    {
      imageId: "newHorizonP",
      image: "/imageList/newHorizon/plutoCharonAp.png",
      imageCredit: "NASA/JHUAPL/SWRI",
      imageDesc:
        "Pluto and Charon as they are approximately. Images collected seperately and put together",
    },
    {
      imageId: "newHorizonQ",
      image: "/imageList/newHorizon/plutoMnt.png",
      imageCredit: "NASA/JHUAPL/SWRI",
      imageDesc: "Tartarus Dorsa mountains",
    },
  ],
  recentNews: [
    {
      newsId: "",
      image: "",
      title: "",
      summary: "",
    },
  ],
};
