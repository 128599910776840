import { Box, CardContent, Grid, Link } from "@mui/material";
import { useRecoilValue } from "recoil";
import {
  StyledHomeCardFact,
  StyledHomeMedia,
  StyledHomeTelescopeCard,
  StyledListCardLiftoff,
} from "../../App/styles/StyledTelescopeGridCard";
import { getAllTelescopesInfo } from "../../data/telescope";

export function TopTelescopesOther() {
  const TopTelescopes = useRecoilValue(getAllTelescopesInfo);

  return (
    <Grid container spacing={2} py={5}>
      {TopTelescopes.filter((item) => item.isFavorite).map((item, index) => (
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={3}
          key={index}
          sx={{ alignItems: "stretch", justifyContent: "center", flexDirection: "row-reverse" }}
        >
          <Box display="flex" justifyContent="center">
            <StyledHomeTelescopeCard elevation={0}>
              <Link href={`/telescope/${item.id}`}>
                <StyledHomeMedia
                  component="img"
                  image={item.src}
                  alt={item.credit}
                />
              </Link>

              {/* <Typography variant="caption" sx={{ pl: 2 }}>
                {item.credit}
              </Typography> */}

              <CardContent sx={{ maxHeight: "11em", pt: 1 }}>
                <StyledListCardLiftoff variant="h6">
                  {item.button}
                </StyledListCardLiftoff>
                <StyledHomeCardFact variant="subtitle2" textAlign="flex-start">
                  {item.desc.headline}
                </StyledHomeCardFact>
              </CardContent>
            </StyledHomeTelescopeCard>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
