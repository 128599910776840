export const opportunity = {
    id: "opportunity",
    title: "Opportunity",
    launchDate: "July 8, 2003 ",
    endDate: "February 13, 2019",
    src: "/images/opportunity.png",
    credit: "",
    button: "Opportunity",
    missionStatus: "Past",
    missionType: "rover",
    launchVehicle: "Delta II Heavy",
    launchSite: "Cape Canaveral, Fl",
    keyFact: ["The rover drove into “Endurance Crater” on June 8. It found that as far down as outcrops extended, they bore evidence of extensive exposure to water"],
    desc: {
      descId: "opportunityDesc",
      headline: "",
      mainDesc: "Spirit and Opportunity were solar-powered rovers landed on opposite sides of Mars. Opportunity landed on Jan. 25, 2004 inside a small crater later named Eagle Crater at Meridiani Planum, a place where mineral deposits suggested that Mars had a wet history.  Opportunity climbed out of Eagle Crater on March 22. It examined some rocks and soil on the dark surrounding plain, then headed east toward a stadium-size crater called “Endurance.” It set a one-day martian driving record of 140.9 meters (462 feet) on April 17 and reached the rim of the crater on April 30. Opportunity broke the record for extraterrestrial travel by rolling greater than the distance of a 26-mile (42-kilometer) marathon. ",
      subDesc: "",
    },

    images: [
      {
        imageId: "oppView",
        image: "/imageList/opportunity/oppImg.png",
        imageCredit: "NASA/JPL-Caltech",
        imageDesc: "View from Opportunity's front hazard avoidance camera on Nov. 26, 2014",
      },
    
      
   
      {
        imageId: "marsRock",
        image: "/imageList/opportunity/oppMarsRock.png",
        imageCredit:
          "NASA/JPL-Caltech/Cornell/USGS",
        imageDesc:
          "Small spherules on the Martian surface are near Fram Crater",
      },

      {
        imageId: "priv",
        image: "/imageList/opportunity/oppD.png",
        imageCredit: "NASA/JPL-Caltech/Cornell/USGS/ASU",
        imageDesc: "Area on Mars called 'Private Joseph Field' combines four images from the microscopic imager",
      },
    
      {
        imageId: "oppA",
        image: "/imageList/opportunity/oppMars.png",
        imageCredit: "NASA/JPL-Caltech/Cornell/Arizona State Univ",
        imageDesc: "Eighth Anniversary View From 'Greeley Haven'",
      },
    ],
    // recentNews: [
    //   {
    //     newsId: "",
    //     image: "",
    //     title: "",
    //     summary: "",
    //   },
    // ],
  };