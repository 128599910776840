export const herschel = {
  id: "herschel",
  title: "Herschel Space Observatory",
  launchDate: "June 17, 2013",
  endDate: " June 18, 2013",
  src: "/images/herschel.png",
  credit: "",
  button: "Herschel",
  missionStatus: "Past",
  missionType: "observatory",
  launchVehicle: "Ariane 5",
  launchSite: "	Guiana Space Centre, French Guiana",
  keyFact: [
    "Able to observe dust obscured and cold objects that are invisible to other telescopes.",
  ],
  desc: {
    descId: "",
    headline:
      "Discovering how the first galaxies formed and how they evolved to give rise to present day galaxies like our own",
    mainDesc:
      "Herschel Space Observatory was a space-based telescope that was able to detect radiation at far infrared and sub-millimetre wavelengths.",
    subDesc:
      "The mission focus was on gaining insight about how the first galaxies formed and how they evolved to give rise to present day galaxies like our own. It also provided a unique look at our own solar system.",
  },

  images: [
    {
      imageId: "herschelA",
      image: "/imageList/herschel/ring.png",
      imageCredit: "ESA/NASA/JPL-Caltech/Whitman College",
      imageDesc: "Gigantic ring structure NGC 7538",
    },

    {
      imageId: "herschelB",
      image: "/imageList/herschel/galaxyNGC.png",
      imageCredit:
        "Digitised Sky Survey/NASA Chandra/Southern Observatory for Astrophysical Research/Very Large Array (Robert Dunn et al. 2010)",
      imageDesc: "Elliptical galaxy NGC 1399",
    },

    {
      imageId: "herschelC",
      image: "/imageList/herschel/cassiopia.png",
      imageCredit:
        "ESA/Herschel/NASA/JPL-Caltech CC BY-SA 3.0 IGO; Acknowledgement: R. Hurt (JPL-Caltech)",
      imageDesc:
        " Three regions that make up the complex W3, W4, and W5 in the northern constellation Cassiopeia located over 6000 light years away",
    },

    {
      imageId: "herschelD",
      image: "/imageList/herschel/pinwheel.png",
      imageCredit:
        "ESA/Herschel/NASA/JPL-Caltech CC BY-SA 3.0 IGO; Acknowledgement: R. Hurt (JPL-Caltech)",
      imageDesc: "Pinwheel Galaxy located in the constellation Ursa Major",
    },
    {
      imageId: "herschelE",
      image: "/imageList/herschel/fireworks.png",
      imageCredit:
        "X-ray: NASA/CXC/INAF/P.Tozzi, et al; Optical: NAOJ/Subaru and ESO/VLT; Infrared: ESA/Herschel/J. Santos, et al.",
      imageDesc:
        "Galaxy cluster XDCPJ0044.0-2033 with an estimated mass of about four hundred thousand billion times that of our Sun",
    },
    {
      imageId: "herschelF",
      image: "/imageList/herschel/orionB.png",
      imageCredit:
        "ESA/Herschel/NASA/JPL-Caltech CC BY-SA 3.0 IGO; Acknowledgement: R. Hurt (JPL-Caltech)",
      imageDesc:
        "1300 light-years from us, Orion B molecular cloud, a vast star-forming complex in the constellation Orion, the Hunter.",
    },
    {
      imageId: "herschelG",
      image: "/imageList/herschel/taurus.png",
      imageCredit:
        "ESA/Herschel/NASA/JPL-Caltech CC BY-SA 3.0 IGO; Acknowledgement: R. Hurt (JPL-Caltech)",
      imageDesc:
        "Mosaic of the Taurus Molecular Cloud about 450 light-years from us",
    },
  ],
  recentNews: [
    {
      newsId: "",
      image: "",
      title: "",
      summary: "",
    },
  ],
};
