import CircleIcon from "@mui/icons-material/Circle";
import RocketIcon from "@mui/icons-material/Rocket";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import {
  Avatar,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { StyledFactsCard } from "../../App/styles/StylesTelescopePage";
import { getTelescopeInfo } from "../../data/telescope";

export function FactTable() {
  const { telescopeId } = useParams();
  const telescope = useRecoilValue(getTelescopeInfo(telescopeId));

  const facts = telescope.keyFact.map((item) => {
    return item;
  });

  const KeyFactItem = ({ icon, label, info }) => {
    return (
      <ListItem my={3}>
        <Avatar sx={{ mr: 4 }}>{icon}</Avatar>
        <ListItemText primary={label} secondary={info} />
      </ListItem>
    );
  };

  return (
    <Box display="flex" flexDirection="column" p={2}>
      <StyledFactsCard variant="outlined">
        <CardContent>
          <Typography variant="h5" pb={3}>
            Key Facts
          </Typography>

          <Divider sx={{ mb: 1 }} />

          <KeyFactItem
            icon={<RocketIcon />}
            label="Launch Vehicle"
            info={telescope.launchVehicle}
          />

          <KeyFactItem
            icon={<RocketLaunchIcon />}
            label="Launch Site"
            info={telescope.launchSite}
          />

          <Divider />

          {!!telescope.keyFact.length && (
            <List disablePadding>
              {facts.map((name, index) => (
                <ListItem key={index} alignItems="flex-start">
                  <ListItemIcon sx={{ minWidth: "30px", mt: 1, pb: 1 }}>
                    <CircleIcon sx={{ fontSize: "small" }} />
                  </ListItemIcon>
                  <Typography sx={{ lineHeight: 1.3 }}>{name}</Typography>
                </ListItem>
              ))}
            </List>
          )}
        </CardContent>
      </StyledFactsCard>
    </Box>
  );
}
