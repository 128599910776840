import { FormGroup, FormLabel } from "@mui/material";
import { useRecoilState } from "recoil";
import {
  filteredTelescopesTypes,
  FLYBY,
  OBSERVE,
  ORBITAL,
  ROVER,
} from "../../data/telescope";
import { CheckboxGroup } from "./CheckboxGroup";

export function MissionTypeCheckboxes() {
  const [checkedTypes, setCheckedTypes] = useRecoilState(
    filteredTelescopesTypes
  );

  return (
    <FormGroup>
      <FormLabel>Types:</FormLabel>
      <CheckboxGroup
        options={[
          [OBSERVE, "Observatory"],
          [FLYBY, "Fly By"],
          [ORBITAL, "Orbital"],
          [ROVER, "Rover"],
        ]}
        selectedValues={checkedTypes}
        setValues={setCheckedTypes}
      />
    </FormGroup>
  );
}
