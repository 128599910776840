import { ShowXSmall } from "../../updates/ShowBoxes";
import {
  FilterSortCollapse,
  FilterStatusCollapse,
  FilterTypesCollapse,
} from "./FilterCollapse";

export const XSmallFilter = () => {
  return (
    <ShowXSmall sx={{bgcolor:"#f8f8f9"}}>
      {/* <Toolbar>
        {/* <Menus isIcon /> 
        <SortSelect />
        <ScrollTop isMobile />

        <Box sx={{ flexGrow: 1 }} />
        {/* <Menus label={<SortIcon />}>
          <Box p={2} >
            <SortSelect />
          </Box>
        </Menus> 
        <Menus label={<FilterListIcon />}>
          <MissionStatusFilter />
        </Menus>
        <Menus label={<MergeTypeIcon />}>
          <MissionTypeCheckboxes />
        </Menus>
      </Toolbar> */}

      <FilterSortCollapse />

      <FilterStatusCollapse />

      <FilterTypesCollapse />
    </ShowXSmall>
  );
};
