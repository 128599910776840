// import { ace } from "./telescopeData/ace";
import { artemis } from "./telescopeData/artemis";
import { bepiColombo } from "./telescopeData/bepi";
import { cassini } from "./telescopeData/cassini";
import { chandra } from "./telescopeData/chandra";
import { curiosity } from "./telescopeData/curiosity";
// import { compton } from "./telescopeData/compton";
import { davinci } from "./telescopeData/davinci";
// import { envision } from "./telescopeData/envision";
import { fermi } from "./telescopeData/fermi";
import { gaia } from "./telescopeData/gaia";
import { galileo } from "./telescopeData/galileo";
import { heao } from "./telescopeData/heao";
import { herschel } from "./telescopeData/herschel";
import { hipparcos } from "./telescopeData/hipparcos";
import { hubble } from "./telescopeData/hubble";
import { juno } from "./telescopeData/juno";
import { kepler } from "./telescopeData/kepler";
import { lunar } from "./telescopeData/lunar";
import { marsOrbiter } from "./telescopeData/marsOrbiter";
import { messenger } from "./telescopeData/messenger";
import { newHorizons } from "./telescopeData/newHorizons";
// import { nicer } from "./telescopeData/nicer";
// import { nustar } from "./telescopeData/nustar";
import { opportunity } from "./telescopeData/opportunity";
import { origins } from "./telescopeData/origins";
import { roman } from "./telescopeData/roman";
import { spirit } from "./telescopeData/spirit";
import { spitzer } from "./telescopeData/spitzer";
import { swift } from "./telescopeData/swift";
import { tess } from "./telescopeData/tess";
import { veritas } from "./telescopeData/veritas";
import { voyager1 } from "./telescopeData/voyager1";
import { voyager2 } from "./telescopeData/voyager2";
import { webb } from "./telescopeData/webb";
import { wise } from "./telescopeData/wise";

const telescopesNew = [
  // ace,
  artemis,
  bepiColombo,
  cassini,
  chandra,
  // compton,
  curiosity,
  davinci,
  // envision,
  fermi,
  gaia,
  galileo,
  heao,
  herschel,
  hipparcos,
  hubble,
  juno,
  kepler,
  lunar,
  marsOrbiter,
  messenger,
  newHorizons,
  // nicer,
  // nustar,
  opportunity,
  origins,
  roman,
  spirit,
  spitzer,
  swift,
  tess,
  veritas,
  voyager1,
  voyager2,
  webb,
  wise,
];

export const data = {
  telescopes: telescopesNew,
};
