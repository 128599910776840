export const roman = {
  id: "roman",
  title: "Roman Space Telescope",
  launchDate: "2027",
  endDate: "",
  src: "/images/roman.png",
  credit: "",
  button: "Roman",
  missionStatus: "planned",
  missionType: "observatory",
  launchVehicle: "TBD",
  launchSite: "	Cape Canaveral",
  keyFact: [
    "Provide the largest-scale high-resolution images of the universe that astronomers have ever had",
    "Uncover thousands of exoplanets beyond our solar system, including types of planets never surveyed before",
  ],
  desc: {
    descId: "romanDesc",
    headline: "Study the expansion history of the universe",
    mainDesc:
      "The Roman Space Telescope is a NASA observatory designed to unravel the secrets of dark energy and dark matter, search for and image exoplanets, and explore many topics in infrared astrophysics. It will do wide-field surveys of billions of galaxies and measure dark matter around them, search for new dwarf planets, comets, and asteroids in our solar system.",
    subDesc: "",
  },

  // images: [
  //   {
  //     imageId: "",
  //     image: "",
  //     imageCredit: "",
  //     imageDesc: "",
  //   },

  //   {
  //     imageId: "",
  //     image: "",
  //     imageCredit: "",
  //     imageDesc: "",
  //   },

  //   {
  //     imageId: "",
  //     image: "",
  //     imageCredit: "",
  //     imageDesc: "",
  //   },

  //   {
  //     imageId: "",
  //     image: "",
  //     imageCredit: "",
  //     imageDesc: "",
  //   },
  // ],
  recentNews: [
    {
      newsId: "roman1",
      image: "/images/romanNews.png",
      imageCredit: "NASA",
      title: "Field of view much larger than Hubble's ",
      month: "5",
      year: "2020",
      summary:
        "The Wide-Field Infrared Survey Telescope (WFIRST), targeted for launch in 2025–2026, will have a field of view 100 times larger than Hubble's with comparable sensitivity and resolution. WFIRST's Wide Field Instrument (WFI) will provide survey-sized imaging and slitless spectroscopy data sets with space-based resolution, achieving survey speeds hundreds to a thousand times faster than possible with Hubble. WFIRST surveys will enable in-depth investigations in many areas of astrophysics where existing facilities have thus far provided only tantalizing glimpses. WFIRST also includes a technology demonstration Coronagraphic Instrument that will provide orders-of-magnitude improvement in contrast over existing coronagraphs, and push forward our ability to directly image and characterize exoplanets. ",
      link: "https://www.stsci.edu/contents/newsletters/2020-volume-37-issue-01/wfirst-mission-update?filterUUID=71b100d3-5ab5-4c45-8fb8-80643fa567f5&filterPage=articles&filterName=roman-news",
    },
  ],
};
