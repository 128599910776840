export const marsOrbiter = {
  id: "marsOrbit",
  title: "Mars Reconnaissance Orbiter",
  launchDate: "August 12, 2005",
  endDate: "",
  src: "/images/marsObserver.png",
  credit: "",
  button: "Mars Orbiter",
  missionStatus: "active",
  missionType: "orbital",
  launchVehicle: "Atlas V",
  launchSite: "	Cape Canaveral Air Force Station, Fl",
  keyFact: ["studies Mars with a high-powered camera"],
  desc: {
    descId: "",
    headline: "",
    mainDesc: "",
    subDesc: "",
  },

  images: [
    {
      imageId: "",
      image: "",
      imageCredit: "",
      imageDesc: "",
    },

    {
      imageId: "",
      image: "",
      imageCredit: "",
      imageDesc: "",
    },

    {
      imageId: "",
      image: "",
      imageCredit: "",
      imageDesc: "",
    },

    {
      imageId: "",
      image: "",
      imageCredit: "",
      imageDesc: "",
    },
  ],
  recentNews: [
    {
      newsId: "",
      image: "",
      title: "",
      summary: "",
    },
  ],
};
