import { FormCheckboxes } from "./FormCheckboxes";

export function CheckboxGroup({
  options = [],
  selectedValues = [],
  setValues = () => {},
}) {
  const handleChange = (event, value) => {
    const name = event.target.name.toLowerCase();

    setValues(
      value === true
        ? [...selectedValues, name]
        : selectedValues.filter((item) => item !== name)
    );
  };

  return options.map(([name, label]) => (
    <FormCheckboxes
      name={name}
      onChange={handleChange}
      checked={selectedValues.includes(name)}
      label={label}
      sx={{color: "#051e37"}}
    />
  ));
}
