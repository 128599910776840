import { CardActionArea, CardContent, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import ReactBnbGallery from "react-bnb-gallery";
import "react-bnb-gallery/dist/style.css";
import { useRecoilValue } from "recoil";
import { StatusBox } from "../../App/StatusBox";
import { getAllFilteredTelescopesInfo } from "../../data/telescope";

import {
  StyledCardLaunchDate,
  StyledCardStatus,
  StyledTelescopeCardMedia,
  StyledTelescopeGridCard,
} from "../../App/styles/StyledTelescopeGridCard";
import { statusLabel } from "../../Styles/styles";

export function GridViewCards() {
  const telescopes = useRecoilValue(getAllFilteredTelescopesInfo);

  const [imageList, setImageList] = useState([]);

  const telescopeImages = imageList || [];

  return (
    <>
      <Grid container mt={2} columns={12}>
        {telescopes.map((item, index) => (
          <Grid
            item
            key={index}
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={3}
            sx={{
              alignItems: "stretch",
              border: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            <StyledTelescopeGridCard display="flex" elevation={0}>
              <CardActionArea href={`/telescope/${item.id}`}>
                <StyledTelescopeCardMedia
                  component="img"
                  image={item.src}
                  alt={item.title}
                />
                <CardContent sx={{ pt: 1 }}>
                  <Typography variant="h5" fontSize="large">
                    {item.button}
                  </Typography>
                  <>
                    <StyledCardStatus
                      component="div"
                    
                    >
                      Liftoff
                    </StyledCardStatus>
                    <StyledCardLaunchDate
                      component="div"
                    
                    >
                      {item.launchDate}
                    </StyledCardLaunchDate>
                  </>
                  <StatusBox
                    item={item}
                    statusLabel={statusLabel}
                    variant="subtitle2"
                  />
                </CardContent>
              </CardActionArea>
            </StyledTelescopeGridCard>
          </Grid>
        ))}
      </Grid>

      <ReactBnbGallery
        show={!!telescopeImages.length}
        activePhotoIndex={0}
        onClose={() => setImageList([])}
        preloadSize={8}
        photos={telescopeImages.map((image) => ({
          photo: image.image,
          caption: image.imageDesc,
          subcaption: image.imageCredit,
        }))}
      />
    </>
  );
}
