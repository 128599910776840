import { Box, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledImgHeaderWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
});

export const StyledImgHeaderStack = styled(Stack)({
  display: "flex",
  justifyContent: "space-between",
  flexGrow: 1,
  maxWidth: 1200,
});
