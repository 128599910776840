export const messenger = {
  id: "messenger",
  title: "Messenger",
  launchDate: "August 3, 2004",
  endDate: "April 30, 2015",
  src: "/images/messenger.png",
  credit: "credit image",
  button: "MESSENGER",
  missionStatus: "Past",
  missionType: "orbital",
  launchVehicle: "	Delta II",
  launchSite: "Cape Canaveral, Fl",
  keyFact: [
    "Globally mapped both in high-resolution monochrome and in color, the entire surface  of Mercury     ",
    "Found evidence of water ice at Mercury’s poles, frozen at locations that never see the sunlight",
  ],
  desc: {
    descId: "messengerInfo",
    headline: "Messenger was the first spacecraft to orbit Mercury and it also performed a flyby of Earth and Venus. ",
    mainDesc:
      "The Mercury Surface, Space Environment, Geochemistry and Ranging (MESSENGER) was NASA robotic space probe that orbited the planet Mercury studying the characteristics and environment of Mercury from orbit.  On it’s voyage to Mercury Messenger performed a flyby of Earth and Venus. ",
    subDesc:
      "MESSENGER journeyed through the inner solar system and became the first spacecraft ever to orbit Mercury. It achieved 100% mapping of Mercury on March 6, 2013.  MESSENGER impacted the surface of Mercury on April 30, 2015, ending it’s successful operations.",
  },

  images: [
    {
      imageId: "messengerA",
      image: "/imageList/messenger/mercuryCaloris.png",
      imageCredit:
        "NASA/Johns Hopkins University Applied Physics Laboratory/Arizona State University/Carnegie Institution of Washington. Image reproduced courtesy of Science/AAAS",
      imageDesc:
        "Mercury's Caloris impact basin, visible in this image as a large, circular, orange feature in the center of the picture.",
    },
    {
      imageId: "messengerB",
      image: "/imageList/messenger/mercuryHorizon.png",
      imageCredit:
        "NASA/Johns Hopkins University Applied Physics Laboratory/Carnegie Institution of Washington",
      imageDesc: "Mosaic of Mercury's horizon",
    },
    {
      imageId: "messengerC",
      image: "/imageList/messenger/mercuryPlains.png",
      imageCredit: "NASA/JHU/APL/CIW",
      imageDesc: "Smooth plains of Borealis Planitia of Mercury",
    },
    {
      imageId: "messengerD",
      image: "/imageList/messenger/venusClouds.png",
      imageCredit: "NASA/JHU/APL",
      imageDesc: "Surface of Venus covered in clouds",
    },
    {
      imageId: "messengerE",
      image: "/imageList/messenger/mercuryWater.png",
      imageCredit:
        "NASA/Johns Hopkins University Applied Physics Laboratory/Carnegie Institution of Washington",
      imageDesc:
        " Mercury's north polar region, yellow show evidence for water ice",
    },
    {
      imageId: "messengerF",
      image: "/imageList/messenger/mercuryCalorisColor.png",
      imageCredit: "NASA",
      imageDesc: "Enhanced-coloor mosaic of Caloris basin",
    },
    {
      imageId: "messengerG",
      image: "/imageList/messenger/earth.png",
      imageCredit:
        "NASA, Jet Propulsion Laboratory, Applied Physics Laboratory",
      imageDesc: "A view of Earth",
    },
    {
      imageId: "messengerL",
      image: "/imageList/messenger/mercuryColor.png",
      imageCredit:
        "NASA/Johns Hopkins University Applied Physics Laboratory/Carnegie Institution of Washington",
      imageDesc: "A view of Mercury in color",
    },
    {
      imageId: "messengerH",
      image: "/imageList/messenger/mercury.png",
      imageCredit:
        "NASA/Johns Hopkins University Applied Physics Laboratory/Carnegie Institution of Washington",
      imageDesc: "A view of Mercury",
    },
    {
      imageId: "messengerI",
      image: "/imageList/messenger/earthHalf.png",
      imageCredit:
        "NASA/Johns Hopkins University Applied Physics Laboratory/Carnegie Institution of Washington",
      imageDesc: "A view of Earth",
    },
    {
      imageId: "messengerJ",
      image: "/imageList/messenger/mercuryData.png",
      imageCredit:
        "NASA/Johns Hopkins University Applied Physics Laboratory/Carnegie Institution of Washington",
      imageDesc: "Chemical composition of Mercury",
    },
    {
      imageId: "messengerK",
      image: "/imageList/messenger/mercuryCraters.png",
      imageCredit:
        "NASA/Johns Hopkins University Applied Physics Laboratory/Carnegie Institution of Washington",
      imageDesc:
        "Crater Debussy can be seen towards the bottom of the globe. The peak-ring basin Rachmaninoff can be seen towards the eastern edge",
    },
  ],
  recentNews: [
    {
      newsId: "",
      image: "",
      title: "",
      summary: "",
    },
  ],
};
