export const gaia = {
  id: "gaia",
  title: "Gaia",
  launchDate: "December 19, 2013",
  endDate: " active",
  src: "/images/gaia.png",
  credit: "credit image",
  button: "GAIA",
  missionStatus: "active",
  missionType: "orbital",
  launchVehicle: "Delta 7920H",
  launchSite: "Cape Canaveral, Fla.",
  keyFact: [
    "Gaia monitored each of its target objects an average of 70 times over five years to create a record of the brightness and the position of each star over time",
  ],
  desc: {
    descId: "gaiaInfo",
    headline: "Gaia is creating the largest 3-D map of the Milky Way Galaxy",
    mainDesc:
      "Gaia is a space observatory of the European Space Agency (ESA), designed for measuring the positions, distances and motions of stars with unprecedented precision. Gaia spins once every six hours, sweeping its two telescopes across the entire sky to observe a billion stars creating a record of the brightness and the position of each star over time. ",
    subDesc: " ",
  },

  // images: [
  //   {
  //     imageId: "gaiaA",
  //     image:
  //       "/imageList/heaoB/Einstein_Observatory___Center_for_Astrophysics.png",
  //      imageCredit: "",
  //   imageDesc:"",

  //   },
  //   {
  //     imageId: "gaiaB",
  //     image: "/imageList/heaoB/heaoBCarinae.png",
  //          imageCredit: "",
  //   imageDesc:"",

  //   },
  //   {
  //     imageId: "gaiaC",
  //     image: "/imageList/heaoB/heaoBCassiopeiaSupernovaRemnant.png",
  //        imageCredit: "",
  //   imageDesc:"",

  //   },
  //   {
  //     imageId: "gaiaD",
  //     image: "/imageList/heaoB/heaoBNebulaAndromeda.png",
  //       imageCredit: "",
  //    imageDesc:"",

  //   },
  //   {
  //     imageId: "gaiaE",
  //     image: "/imageList/heaoB/HeaoBCrabNebula.png",
  //        imageCredit: "",
  //   imageDesc:"",

  //   },
  //   {
  //     imageId: "gaiaF",
  //     image: "/imageList/heaoB/HeaoBQuasar.png",
  //        imageCredit: "",
  //   imageDesc:"",

  //   },
  //   {
  //     imageId: "gaiaG",
  //     image: "/imageList/heaoB/HeaoBTychosSupernova.png",
  //        imageCredit: "",
  //  imageDesc:"",

  //   },
  // ],
  recentNews: [
    {
      newsId: "",
      image: "",
      title: "",
      summary: "",
    },
  ],
};
