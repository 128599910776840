export const hipparcos = {
  id: "hipparcos",
  title: "Hipparcos",
  launchDate: "August 8, 1989",
  endDate: "August 15, 1993",
  src: "/images/hipparcos.png",
  credit: "credit image",
  button: "Hippacros",
  missionStatus: "Past",
  missionType: "orbital",
  launchVehicle: "Ariane 4",
  launchSite: "Kourou, French Guyana",
  keyFact: [
    "Discovered that the Milky Way is changing shape",
    "Confirmed Einstein's prediction of the effect of gravity on starlight, altered the cosmic distance scale",
    "Discovered that the Universe bigger and younger than previously believed",
  ],
  desc: {
    descId: "hipparcosInfo",
    headline:
      "Pinpointed more than 100 000 stars with more accuracy than ever before",
    mainDesc:
      "Hipparcos, High Precision Parallax Collecting Satellite was scientific satellite of the European Space Agency (ESA). It was the first devoted to precision astrometry- the accurate measurement of the positions of celestial objects on the sky. Hipparcos successfully observed the celestial sphere for 3.5 years and pinpointed the positions of more than one hundred thousand stars with high precision, and more than one million stars with lesser precision.",
    subDesc:
      "Calculations from observations generated the Hipparcos Catalogue of 118,218 stars. Using this data astronomers were also able to identify groups of stars that move along the same waves across the spiral arms of the Milky Way. In particular, they identified a small clump of extremely old stars that are still moving on very similar orbits – a fossil relict of one of our Galaxy's first building blocks. This was one of the first proofs that galaxies build up in a hierarchical way, through the merging of smaller structures into increasingly larger ones.",
  },

  images: [
    {
      imageId: "hipparcosA",
      image:
        "/imageList/hipparcos/Did_the_Pleiades__blind__Hipparcos____Astronomy_com.png",
      imageCredit: "John Chumack",
      imageDesc:
        "Pleiades cluster- 440 light-years from Earth in the constellation Taurus",
    },
    {
      imageId: "hipparcosB",
      image:
        "/imageList/hipparcos/ESA_Science___Technology_-_A_Viking_galaxy_found_by_Hipparcos_shows_how_the_Milky_Way_grew.png",
      imageCredit: "",
      imageDesc: "",
    },
    {
      imageId: "hipparcosC",
      image:
        "/imageList/hipparcos/ESA_Science___Technology_-_The_Hipparcos_all-sky_map.png",
      imageCredit: "ESA/Hipparcos/J. de Bruijne",
      imageDesc:
        "Our galaxy, the Milky Way, positioned along the central row of the image reproduces the night sky as the human eye would perceive it after getting used to the darkness",
    },
    {
      imageId: "hipparcosD",
      image: "/imageList/hipparcos/cygnusLyra.png",
      imageCredit: "ESA",
      imageDesc: "Cygnus Lyra",
    },
    {
      imageId: "hipparcosE",
      image: "/imageList/hipparcos/scorpius.png",
      imageCredit: "ESA",
      imageDesc: "Scorpius",
    },
    {
      imageId: "hipparcosF",
      image: "/imageList/hipparcos/ursaMajor.png",
      imageCredit: "ESA",
      imageDesc: "Ursa Major",
    },
    {
      imageId: "hipparcosG",
      image: "/imageList/hipparcos/alphaPer.png",
      imageCredit: "ESA",
      imageDesc: "Alpha Per",
    },
    {
      imageId: "hipparcosH",
      image: "/imageList/hipparcos/51pegasi.png",
      imageCredit: "ESA",
      imageDesc: "51 Pegasi",
    },
    {
      imageId: "hipparcosI",
      image: "/imageList/hipparcos/arcturus.png",
      imageCredit: "ESA",
      imageDesc: "Arcturus",
    },
    {
      imageId: "hipparcosI",
      image: "/imageList/hipparcos/betaDoradus.png",
      imageCredit: "ESA",
      imageDesc: "Beta Doradus",
    },
    {
      imageId: "hipparcosJ",
      image: "/imageList/hipparcos/betaHya.png",
      imageCredit: "ESA",
      imageDesc: "Beta Hya",
    },
    {
      imageId: "hipparcosK",
      image: "/imageList/hipparcos/cassiopeia.png",
      imageCredit: "ESA",
      imageDesc: "Cassiopeia",
    },
    {
      imageId: "hipparcosL",
      image: "/imageList/hipparcos/cepheid.png",
      imageCredit: "ESA",
      imageDesc: "Cepheid",
    },
    {
      imageId: "hipparcosM",
      image: "/imageList/hipparcos/comaBer.png",
      imageCredit: "ESA",
      imageDesc: "Coma Ber",
    },
    {
      imageId: "hipparcosN",
      image: "/imageList/hipparcos/cygni.png",
      imageCredit: "ESA",
      imageDesc: "Cygni",
    },
    {
      imageId: "hipparcosO",
      image: "/imageList/hipparcos/gammaDraconis.png",
      imageCredit: "ESA",
      imageDesc: "Gamma Draconis",
    },
    {
      imageId: "hipparcosP",
      image: "/imageList/hipparcos/groombridge.png",
      imageCredit: "ESA",
      imageDesc: "Groombridge",
    },
    {
      imageId: "hipparcosQ",
      image: "/imageList/hipparcos/hyades.png",
      imageCredit: "ESA",
      imageDesc: "Hyades",
    },
    {
      imageId: "hipparcosR",
      image: "/imageList/hipparcos/ic2391.png",
      imageCredit: "ESA",
      imageDesc: "IC 2391",
    },
    {
      imageId: "hipparcosS",
      image: "/imageList/hipparcos/pleiades.png",
      imageCredit: "ESA",
      imageDesc: "Pleiades",
    },
    {
      imageId: "hipparcosT",
      image: "/imageList/hipparcos/praesepe.png",
      imageCredit: "ESA",
      imageDesc: "Praesepe",
    },
    {
      imageId: "hipparcosU",
      image: "/imageList/hipparcos/sirius.png",
      imageCredit: "ESA",
      imageDesc: "Sirius",
    },
  ],
  recentNews: [
    {
      newsId: "",
      image: "",
      title: "",
      summary: "",
    },
  ],
};
