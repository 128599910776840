export const voyager1 = {
  id: "voyager1",
  title: "Voyager 1",
  launchDate: "September 5, 1977",
  endDate: " active",
  src: "/images/voyager1.png",
  credit: "credit image",
  button: "Voyager 1",
  missionStatus: "active",
  missionType: "flyby",
  launchVehicle: "Titan IIIE-Centaur",
  launchSite: "Cape Canaveral, Fla.",
  keyFact: [
    "Imaged the moons Amalthea, Io, Europa, Ganymede, and Callisto, showing details of their terrain for the first time",
    "Captured about 60 images of the Sun and planets -- the first portrait of our solar system as seen from the outside, taken 3.7 billion miles from the Sun",
    "Carries a copy of the Golden Record",
  ],
  desc: {
    descId: "voyagerInfo",
    headline:
      "Along with it's sister ship Voyager 2, Voyager 1 has been flying longer than any other spacecraft in history",
    mainDesc:
      "Voyager 1 is a space probe with the mission of studying the outer Solar System and interstellar space beyond the heliosphere.  It is the first spacecraft to reach interstellar space. The probe officially entered interstellar space in August 2012, almost 35 years after its voyage began.",
    subDesc:
      "At a distance of 14.455 billion miles from Earth as of November 28, 2021, it is the most distant artificial object from Earth. Voyager is carrying a golden record which holds voices, images, music, and a variety of natural and human-made sounds like storms, volcanoes, rocket launches, airplanes and animals from Earth out into the cosmos.",
  },

  images: [
    {
      imageId: "voyager1A",
      image:
        "/imageList/voyager1/Cursor_and_Voyager_-_Images_Voyager_Took_of_Jupiter (1).png",
      imageCredit: "NASA/JPL",
      imageDesc: "Jupiter's moon Callisto",
    },
    {
      imageId: "voyager1B",
      image:
        "/imageList/voyager1/Cursor_and_Voyager_-_Images_Voyager_Took_of_Jupiter (2).png",
      imageCredit: "NASA/JPL",
      imageDesc: "Jupiter and two moons",
    },
    {
      imageId: "voyager1C",
      image:
        "/imageList/voyager1/Cursor_and_Voyager_-_Images_Voyager_Took_of_Jupiter (3).png",
      imageCredit: "NASA/JPL",
      imageDesc: "Jupiter and moons",
    },
    {
      imageId: "voyager1D",
      image:
        "/imageList/voyager1/Cursor_and_Voyager_-_Images_Voyager_Took_of_Jupiter (4).png",
      imageCredit: "NASA/JPL",
      imageDesc: "Jupiter's ring",
    },
    {
      imageId: "voyager1E",
      image:
        "/imageList/voyager1/Cursor_and_Voyager_-_Images_Voyager_Took_of_Jupiter.png",
      imageCredit: "NASA/JPL",
      imageDesc: "Jupiter's Great Red Spot close up view",
    },
    {
      imageId: "voyager1F",
      image:
        "/imageList/voyager1/Cursor_and_Voyager_-_Images_Voyager_Took_of_Saturn (1).png",
      imageCredit: "NASA/JPL",
      imageDesc: "Saturn's rings",
    },
    {
      imageId: "voyager1G",
      image:
        "/imageList/voyager1/Cursor_and_Voyager_-_Images_Voyager_Took_of_Saturn.png",
      imageCredit: "NASA/JPL",
      imageDesc: "Saturn's moon Tethys",
    },
    {
      imageId: "voyager1H",
      image:
        "/imageList/voyager1/Voyager_-_Images_Voyager_Took_of_Jupiter (1).png",
      imageCredit: "NASA/JPL",
      imageDesc: "Jupiter's moon Io seen with active volcanoes",
    },
    {
      imageId: "voyager1I",
      image: "/imageList/voyager1/Voyager_-_Images_Voyager_Took_of_Jupiter.png",
      imageCredit: "NASA/JPL",
      imageDesc: "Jupiter's great red spot",
    },
    {
      imageId: "voyager1J",
      image:
        "/imageList/voyager1/Voyager_-_Images_Voyager_Took_of_Saturn (1).png",
      imageCredit: "NASA/JPL",
      imageDesc: "F-ring of Saturn",
    },
    {
      imageId: "voyager1K",
      image:
        "/imageList/voyager1/Voyager_-_Images_Voyager_Took_of_Saturn (2).png",
      imageCredit: "NASA/JPL",
      imageDesc: "Saturn's moon Dione",
    },
    {
      imageId: "voyager1L",
      image: "/imageList/voyager1/Voyager_-_Images_Voyager_Took_of_Saturn.png",
      imageCredit: "NASA/JPL",
      imageDesc: "Saturn's moon Encduales",
    },
  ],
  recentNews: [
    {
      newsId: "",
      image: "",
      title: "",
      summary: "",
    },
  ],
};
