import { Card, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useRecoilValue } from "recoil";
import { getAllNewsDataForTelescope } from "../../data/telescope";
import { NewsCard } from "./NewsCard";

const useStyles = makeStyles({
  card: {
    variant: "outlined",
    minHeight: "100%",
    // minWidth: "20em",
    // maxWidth: "24em",
    justifyContent: "space-evenly",
    margin: 4,
  },
});

export function NewsView({ telescopeId, gridView, maxNumberOfViews }) {
  const classes = useStyles();

  const newsDataAll = useRecoilValue(
    getAllNewsDataForTelescope(telescopeId, true)
  );

  const sorter = (a, b) => {
    return b.year - a.year || b.month - a.month;
  };

  const sortedNews = newsDataAll.filter((item) => item.year).sort(sorter);

  const newsData =
    maxNumberOfViews === undefined
      ? sortedNews
      : sortedNews.slice(0, maxNumberOfViews);

  return gridView ? (
    <>
      <Grid container spacing={2} mt={2} columns={12}>
        {newsData.map((item) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            xl={3}
            sx={{ alignItems: "stretch" }}
          >
            <NewsCard newsData={item} />
          </Grid>
        ))}
      </Grid>
    </>
  ) : (
    <>
      {newsData.map((item) => (
        <Card key={item.newsId} className={classes.card}>
          <NewsCard newsData={item} />
        </Card>
      ))}
    </>
  );
}
