export const curiosity = {
    id: "curiosity",
    title: "Curiosity",
    launchDate: "November 26, 2011",
    endDate: "",
    src: "/images/curiosity.png",
    credit: "",
    button: "Curiosity",
    missionStatus: "active",
    missionType: "rover",
    launchVehicle: "Atlas V-451",
    launchSite: "Cape Canaveral Air Force Station, Florida",
    keyFact: ["Demonstrated long-range mobility on Mars for studying diverse environments and analyzing samples found in different settings"],
    desc: {
      descId: "curiosityDesc",
      headline: "Curiosity is the largest and most capable rover ever sent to Mars",
      mainDesc: "landed on Mars on Aug. 5, 2012. . The rover studies the geology and environment of selected areas in the crater and analyzes samples drilled from rocks or scooped from the ground. Early in its mission, Curiosity's scientific tools found chemical and mineral evidence of past habitable environments on Mars. It continues to explore the rock record from a time when Mars could have been home to microbial life. ",
      subDesc: "",
    },

    // images: [
    //   {
    //     imageId: "",
    //     image: "",
    //     imageCredit: "",
    //     imageDesc: "",
    //   },
    //   {
    //     imageId: "",
    //     image: "",
    //     imageCredit:
    //       "",
    //     imageDesc:
    //       "",
    //   },
    //   {
    //     imageId: "",
    //     image: "",
    //     imageCredit: "",
    //     imageDesc: "",
    //   },
    //   {
    //     imageId: "hubbleM",
    //     image: "",
    //     imageCredit: "NASA",
    //     imageDesc: "Hubble Ultra Deep Field is an image of a small area of space in the constellation Fornax, created using Hubble Space Telescope data from 2003 and 2004. By collecting faint light over many hours of observation, it revealed thousands of galaxies, both nearby and very distant, making it the deepest image of the universe ever taken at that time",
    //   },
    // ],
    // recentNews: [
    //   {
    //     newsId: "",
    //     image: "",
    //     title: "",
    //     summary: "",
    //   },
    // ],
};