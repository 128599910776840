export const tess = {
  id: "tess",
  title: "Tess",
  launchDate: "April 18, 2018",
  endDate: " active",
  src: "/images/tess.png",
  credit: "credit image",
  button: "TESS",
  missionStatus: "active",
  missionType: "observatory",
  launchVehicle: "Explorer XCV",
  launchSite: "Cape Canaveral, Florida",
  keyFact: [
    "Discovers New Worlds in a River of Young Stars. The system resides in the recently discovered Pisces-Eridanus stream, a collection of stars less than 3% the age of our solar system that stretches across one-third of the sky",
    "Identified a collection of pulsating red giant stars all across the sky",
  ],
  desc: {
    descId: "tessInfo",
    headline:
      "An all-sky survey mission that will discover thousands of exoplanets around nearby bright stars",
    mainDesc:
      "TESS is conducting a survey more than 85% of the sky in sequential segments, over the course of two years, breaking it up into 26 different sectors. This first-ever spaceborne all-sky transit survey will start with the dome of stars that would be seen from the Southern Hemisphere sky in the first year, then the Northern hemisphere sky in the second year.  The powerful cameras will monitor each strip for 27 days and nights on a mission to find planets by searching for shadows in the light of stars made when a planet crosses in front of it. ",
    subDesc:
      "TESS stars are typically 30-100 times brighter than those surveyed by Kepler. Planets detected around these stars are therefore far easier to characterize with follow-up observations, resulting in refined measurements of planet masses, sizes, densities, and atmospheric properties. Using the known planet size, orbit, and mass data will reveal whether the planets are rocky (like Earth), gas giants (like Jupiter) or something even more unusual.",
  },

  images: [
    {
      imageId: "tessA",
      image: "/imageList/tess/200_000stars.png",
      imageCredit: "NASA/MIT/TESS",
      imageDesc:
        "View of the southern sky along the plane of our galaxy. More than 200,000 stars are visible in this image centered in the constellation Centaurus",
    },
    {
      imageId: "tessB",
      image: "/imageList/tess/sky.png",
      imageCredit: "NASA/MIT/TESS and Ethan Kruse (USRA)",
      imageDesc:
        "Glowing arc and obscuring dust clouds of the Milky Way (left), our home galaxy seen edgewise; the Andromeda galaxy (oval, center left), our nearest large galactic neighbor, located 2.5 million light-years away; and the North America Nebula (lower left) 1,700 light-years away.",
    },
    {
      imageId: "tessC",
      image: "/imageList/tess/sector1.png",
      imageCredit: "NASA/ Goddard Space Flight Center",
      imageDesc: "Southern Hemisphere strip of stars and galaxies",
    },
    {
      imageId: "tessD",
      image: "/imageList/tess/sector11.png",
      imageCredit: "NASA/ Goddard Space Flight Center",
      imageDesc:
        "Strip of stars and galaxies in the southern sky.  Large Magellanic Cloud appears on the right-hand side, and the plane of the Milky Way runs through the center",
    },
    {
      imageId: "tessE",
      image: "/imageList/tess/sector10.png",
      imageCredit: "NASA/ Goddard Space Flight Center",
      imageDesc:
        "Strip of stars and galaxies in the southern sky. Large Magellanic Cloud appears on the right-hand side, and the plane of the Milky Way runs through the center",
    },
    {
      imageId: "tessF",
      image: "/imageList/tess/southSky.png",
      imageCredit: "NASA/MIT/TESS and Ethan Kruse (USRA)",
      imageDesc:
        "Glowing band of the Milky Way, our home galaxy seen edgewise, the Orion Nebula (top), a nursery for newborn stars, and the Large Magellanic Cloud (center), a nearby galaxy located about 163,000 light-years away",
    },
    //   {
    //     imageId: "tessG",
    //     image: "/imageList/tess/HeaoBTychosSupernova.png",
    //       imageCredit: "",
    //   imageDesc:"",

    //   },
  ],
  recentNews: [
    {
      newsId: "tessNewA",
      image: "/imageList/tess/tessSong.png",
      imageCredit: "NASA/MIT/TESS and Ethan Kruse (USRA)",
      title: "A red giant concert in the sky",
      month: "8",
      year: "2021",
      summary:
        "TESS monitors large swaths of the sky for about a month at a time using its four cameras. Its sensitive measurements of stellar brightness make TESS ideal for studying stellar oscillations, an area of research called asteroseismology. The stellar oscillations astronomers observe depend on each star’s interior structure, mass, and size. Larger stars produce longer, deeper pulsations than smaller ones. TESS’s broad coverage allows for measurements uniformly across almost the entire sky. During its two-year primary mission, TESS covered about 75% of the sky. The images were used to develop light curves – graphs of changing brightness – for nearly 24 million stars over 27 days. Machine learning was used to process the measurements. When the neural network finished processing all of the TESS data, it had identified a chorus of 158,505 pulsating red giants.",
      link: "https://www.nasa.gov/feature/goddard/2021/nasa-s-tess-tunes-into-an-all-sky-symphony-of-red-giant-stars",
    },
  ],
};
