export const cassini = {
  id: "cassini",
  title: "Cassini",
  launchDate: "October 15, 1997",
  endDate: "September 15, 2017",
  src: "/images//Cursor_and_Cassini_Saturn_Orbit_Insertion_-_Cassini–Huygens_-_Wikipedia.png",
  credit: "credit image",
  button: "Cassini",
  missionStatus: "Past",
  missionType: "orbital",
  launchVehicle: "IVB/Centaur",
  launchSite: "cape Canaveral, fl",
  keyFact: [
    "Revealed Titan to have rain, rivers, lakes, and seas; it is shrouded in a thick, nitrogen-rich atmosphere that might be similar to what Earth's was like long ago",
    "Observed the formation and development of Saturn's great northern storm of 2010-2011, which eventually encircled the entire planet for months",
    "Discovered a giant hurricane-like vortex at both of the planet's poles",
    "ESA’s Huygens probe parachuted to Titan, making the first landing on a moon in the outer solar system",
  ],
  desc: {
    descId: "cassiniInfo",
    headline: "Cassini spent 13 years exploring Saturn in detail and carried the Huygens probe which parachuted to Titan",
    mainDesc:
      "Cassini was a sophisticated robotic spacecraft with a mission  to orbit and explore Saturn in detail. The voyage to Saturn included flybys of Venus , Earth, and Jupiter. It was also able to study the asteroid 2685.  Cassini carried with it a probe called Huygens, which parachuted to the surface of Saturn’s largest moon, Titan, in January 2005.",
    subDesc:
      "Cassini was active in space for nearly 20 years, with 13 years spent orbiting Saturn and studying the planet and its system.",
  },

  images: [
    {
      imageId: "cassiniA",
      image: "/imageList/cassini/saturn.png",
      imageCredit: "NASA/JPL-Caltech/Space Science Institute",
      imageDesc: "Cassini’s view approximately 1.4 million miles from Saturn",
    },
    {
      imageId: "cassiniA",
      image: "/imageList/cassini/northPole.png",
      imageCredit: "NASA/JPL-Caltech/Space Science Institute",
      imageDesc: "Saturn's north pole on April 26, 2017",
    },
    {
      imageId: "cassiniB",
      image: "/imageList/cassini/odysseus.png",
      imageCredit: "NASA/JPL-Caltech/Space Science Institute",
      imageDesc: "Odysseus crater on Saturn's icy moon Tethys",
    },
    //   {
    //     imageId: "cassiniC",
    //     image: "/imageList/cassini/Media_Gallery___Jupiter_-_Mission_Juno.png",
    //        imageCredit: "",
    //   imageDesc:"",

    //   },
    {
      imageId: "cassiniD",
      image: "/imageList/cassini/infraredTitan.png",
      imageCredit:
        "NASA/JPL-Caltech/Stéphane Le Mouélic, University of Nantes, Virginia Pasek, University of Arizona",
      imageDesc: "Infrared images of Saturn's moon Titan",
    },
    {
      imageId: "cassiniE",
      image: "/imageList/cassini/ioJupiterMoon.png",
      imageCredit: "NASA/JPL/University of Arizona",
      imageDesc: "Io and its shadow against Jupiter",
    },
    {
      imageId: "cassiniF",
      image: "/imageList/cassini/saturnRings.png",
      imageCredit: "NASA/JPL-Caltech/SSI/Cornell",
      imageDesc: "Saturn and it's main rings",
    },
    {
      imageId: "cassiniG",
      image: "/imageList/cassini/rhea.png",
      imageCredit: "NASA/JPL-Caltech/Space Science Institute",
      imageDesc: "Saturn’s icy moon Rhea passes in front of Titan ",
    },
  ],
  recentNews: [
    {
      newsId: "",
      image: "",
      title: "",
      summary: "",
    },
  ],
};
