import { Grid, Divider } from "@mui/material";


export const SectionDivider = ({ sm }) => {
    return (
      <Grid container alignSelf="center" justifyContent="center">
        <Grid item sm={sm} sx={{ my: 5 }}>
          <Divider textAlign="center" variant="middle" />
        </Grid>
      </Grid>
    );
  };