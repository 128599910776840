export const wise = {
  id: "wise",
  title: "WISE",
  launchDate: "December 14, 2009",
  endDate: "",
  src: "/images/wise.png",
  credit: "",
  button: "WISE",
  missionStatus: "active",
  missionType: "observatory",
  launchVehicle: "Delta 347)",
  launchSite: "Vandenberg AFB, Ca",
  keyFact: [
    "Located the first known 'Trojan' asteroid to share the same orbital path around the sun as Earth",
  ],
  desc: {
    descId: "",
    headline:
      "NASA's Wide field infrared survey explorer (WISE) scanned the entire sky twice in infrared light, snapping pictures of three-quarters of a billion objects, including remote galaxies, stars and asteroids",
    mainDesc:
      "Primary mission was to survey the entire sky twice collecting images of  snapping pictures of remote galaxies, stars and asteroids.  Currently, it is on a new quest. Renamed NEOWISE, its new missions primary goal is identifying near-Earth asteroids and other objects",
    subDesc: "",
  },

  images: [
    {
      imageId: "wiseA",
      image: "/imageList/wise/galaxyBright.png",
      imageCredit: "NASA/JPL-Caltech",
      imageDesc:
        "Galaxy, named WISE J224607.57-052635.0, is erupting with light equal to more than 300 trillion suns",
    },

    {
      imageId: "wiseB",
      image: "/imageList/wise/flame.png",
      imageCredit: "NASA/JPL-Caltech/UCLA",
      imageDesc: "Flame Nebula sitting on the eastern hip of Orion the Hunter",
    },

    {
      imageId: "wiseC",
      image: "/imageList/wise/starNebula.png",
      imageCredit: "X-ray: NASA/CXC/SAO; Infrared: NASA/JPL-Caltech",
      imageDesc: " Neutron Star Nebula PSR B1509-58",
    },

    {
      imageId: "wiseD",
      image: "/imageList/wise/galaxyCluster.png",
      imageCredit: "NASA/JPL-Caltech/Gemini/CARMA",
      imageDesc: "Galaxy cluster called MOO J1142+1527",
    },
    {
      imageId: "wiseE",
      image: "/imageList/wise/lastLight.png",
      imageCredit: "NASA/JPL-Caltech/UCLA",
      imageDesc:
        "WISE's last picture taken of the sky shows thousands of stars in a patch of the Milky Way galaxy",
    },
    {
      imageId: "wiseF",
      image: "/imageList/wise/dyingStar.png",
      imageCredit: "NASA/JPL-Caltech/UCLA",
      imageDesc:
        "Dying star, called the Helix nebula, is shown surrounded by the tracks of asteroids",
    },
    {
      imageId: "wiseG",
      image: "/imageList/wise/superNova.png",
      imageCredit:
        "X-ray: NASA/CXC/SAO & ESA; Infared: NASA/JPL-Caltech/B. Williams (NCSU)",
      imageDesc:
        "All that remains of RCW 86, the oldest documented example of a supernova.",
    },
  ],
  recentNews: [
    {
      newsId: "",
      image: "",
      title: "",
      summary: "",
    },
  ],
};
