import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import { Button, Divider, Grid, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import ReactBnbGallery from "react-bnb-gallery";
import "react-bnb-gallery/dist/style.css";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { getTelescopeInfo } from "../../data/telescope";
import {
  StyledImgHeaderStack,
  StyledImgHeaderWrapper,
} from "../styles/StylesTelescopeImgList";
import { TelescopeImagesList } from "./TelescopeImagesList";
import {
  ShowLg,
  ShowOnlyMd,
  ShowOnlySmall,
  ShowXSmall,
} from "../updates/ShowBoxes";

export function ImageContainer() {
  const { telescopeId } = useParams();
  const telescope = useRecoilValue(getTelescopeInfo(telescopeId));

  const [openImage, setOpenImage] = useState();

  if (!telescope) {
    return <div> not found</div>;
  }

  const telescopeImages = telescope.images || [];

  const foundIndex = telescopeImages.findIndex(
    ({ imageId }) => imageId === openImage?.imageId
  );

  return (
    <>
      <Grid
        container
        columns={12}
        spacing={1}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={11} sm={10} md={11} lg={10} xl={10}>
          <StyledImgHeaderWrapper>
            <StyledImgHeaderStack direction="row">
              <div>
                <Typography variant="h4">Mission Images</Typography>
              </div>
              <div>
                <Tooltip title="Image Gallery" id="images">
                  <Button
                    aria-label="picture"
                    sx={{ fontSize: "larger" }}
                    onClick={() => setOpenImage(...telescopeImages)}
                  >
                    <ImageSearchIcon />
                  </Button>
                </Tooltip>
              </div>
            </StyledImgHeaderStack>
          </StyledImgHeaderWrapper>
          <Grid container alignSelf="center" justifyContent="center">
            <Grid item xs={12} xl={11} mt={3}>
              <Divider
                textAlign="center"
                sx={{ maxWidth: 1400, alignSelf: "center", mx: 4 }}
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            key={telescope.telescopeId}
            display="flex"
            justifyContent="center"
          >
            <ShowXSmall>
              <TelescopeImagesList
                telescopeIdToUse={telescope}
                options={telescopeImages}
                cols={1}
                width={410}
              />
            </ShowXSmall>
            <ShowOnlySmall>
              <TelescopeImagesList
                telescopeIdToUse={telescope}
                options={telescopeImages}
                cols={1}
                width={600}
              />
            </ShowOnlySmall>

            <ShowOnlyMd>
              <TelescopeImagesList
                telescopeIdToUse={telescope}
                options={telescopeImages}
                cols={2}
                width={840}
              />
            </ShowOnlyMd>

            <ShowLg>
              <TelescopeImagesList
                telescopeIdToUse={telescope}
                options={telescopeImages}
                cols={3}
                width={1200}
              />
            </ShowLg>
          </Grid>
        </Grid>
      </Grid>

      <ReactBnbGallery
        show={!!openImage}
        activePhotoIndex={foundIndex === -1 ? 0 : foundIndex}
        onClose={() => setOpenImage(undefined)}
        preloadSize={8}
        photos={telescopeImages.map((image) => ({
          photo: image.image,
          caption: image.imageDesc,
          subcaption: image.imageCredit,
        }))}
      />
    </>
  );
}
