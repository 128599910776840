import { Box } from "@mui/material";
import { useMediaQuery } from "../../App/MediaQuery";
import { SpaceAppBar } from "../../AppBar/AppBar";
import { HomePageBody } from "./HomePageBody";
import logo from "./homestarbright.png";

import astro from "../../astroToon.png";

import { ImageTextLarge } from "./ImageTextLg";
import { ImageTextSmall } from "./ImageTextSmall";
import { WideLogoCover } from "./WideLogoCover";
import { AppBarLinks } from "../../AppBar/AppBarLinks";

export function HomePage() {
  const isPageWide = useMediaQuery("(min-width: 1000px)");
  const isImageView = useMediaQuery("(min-width: 800px)");
  const isImageText = useMediaQuery("(max-width: 800px)");
  const isPageSmall = useMediaQuery("(max-width: 999px)");
  
  return (
    <Box>
      {isPageSmall && <SpaceAppBar />}

      <Box
     
      sx={{ position: "relative" }}>
        <img
          src={logo}
          alt="logo"
          style={{
            margin: "auto",
            display: "block",
            width: "100%",
            maxHeight: "95vh",
        
          }}
        />

        {isPageWide && (
          <>
            <WideLogoCover />
            <Box
              sx={{
                middle: 0,
                position: "absolute",
                top: "24px",
                right: "1em",
              }}
            >
              <AppBarLinks />
            </Box>
          </>
        )}

        {isImageText ? <ImageTextSmall /> : <ImageTextLarge />}

        {isImageView && (
          <Box
            sx={{
              middle: 0,
              position: "absolute",
              bottom: "12em",
              right: "19vw",
            }}
          >
            <img
              src={astro}
              alt="logo"
              style={{
                margin: "auto",
                display: "block",
                width: "5em",
                maxHeight: "8em",
              }}
            />
          </Box>
        )}
      </Box>

      <HomePageBody />
    </Box>
  );
}
