import GridViewIcon from "@mui/icons-material/GridView";
import ViewListIcon from "@mui/icons-material/ViewList";
import { Stack, Tooltip } from "@mui/material";
import { useRecoilState } from "recoil";
import { gridViewMode } from "../../data/app";
import { StyledToggle } from "../styles/StyledTelescopeGridCard";

export default function ToggleButtons() {
  const [view, setView] = useRecoilState(gridViewMode);

  return (
    <>
      <Stack direction="row" spacing={1} sx={{ mr: 1, mt: 2 }}>
        <StyledToggle
          aria-label="grid view"
          sx={{
            color: view ? "#103964" : "#677e99",
          }}
          size="small"
          onClick={() => setView(true)}
        >
          <Tooltip title="Grid View" id="grid">
            <GridViewIcon fontSize="small" />
          </Tooltip>
        </StyledToggle>

        <StyledToggle
          aria-label="list view"
          sx={{
            color: !view ? "#103964" : "#677e99",
          }}
          size="small"
          onClick={() => setView(false)}
        >
          <Tooltip title="List View" id="list">
            <ViewListIcon fontSize="small" />
          </Tooltip>
        </StyledToggle>
      </Stack>
    </>
  );
}
