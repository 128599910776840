export const heao = {
  id: "heaoB",
  title: "Einstein Observatory",
  launchDate: "November 13, 1978",
  endDate: "March 25, 1982",
  src: "/images/heaoB.png",
  credit: "credit image",
  button: "Einstein",
  missionStatus: "Past",
  missionType: "observatory",
  launchVehicle: "	Atlas SLV-3D Centaur-D1AR",
  launchSite: "	Cape Canaveral, Fl ",
  keyFact: [
    " Recognized that coronal emissions in normal stars are stronger than expected",
    "First medium and Deep X-ray surveys",
  ],
  desc: {
    descId: "heaoBInfo",

    headline:
      "First fully imaging X-ray telescope put into space. Provided important observations of pulsars, supernova remnants, and supermassive black holes in other galaxies",
    mainDesc:
      "The Einstein Observatory (HEAO 2) was the second of three missions in a program of research in high-energy astronomical phenomena. The Einstein Observatory spacecraft was identical to the HEAO 1 vehicle, with the addition of reaction wheels and associated electronics to enable the telescope to be pointed at sources.",
    subDesc:
      "The Einstein Observatory satellite re-entered the Earth's atmosphere and burned up on March 25, 1982.",
  },

  images: [
    {
      imageId: "heaoBA",
      image: "/imageList/heaoB/w28supernova.png",
      imageCredit: "Seward, F.D. 1990, ApJS 73, 781",
      imageDesc:
        "The W28 supernova remnant, the hot remains of an exploded star",
    },
    {
      imageId: "heaoBB",
      image: "/imageList/heaoB/heaoBCarinae.png",
      imageCredit: "NASA/Marshall Space Flight Center",
      imageDesc:
        "The Eta Carinae Nebula- a large and complex cloud of gas, crisscrossed with dark lanes of dust, about 6,500 light years from Earth",
    },
    {
      imageId: "heaoBC",
      image: "/imageList/heaoB/heaoBCassiopeiaSupernovaRemnant.png",
      imageCredit: "NASA/Marshall Space Flight Center",
      imageDesc: "Supernova remnant Cassiopeia A",
    },
    {
      imageId: "heaoBD",
      image: "/imageList/heaoB/heaoBNebulaAndromeda.png",
      imageCredit: "NASA/Marshall Space Flight Center",
      imageDesc: "Center region of the Great Nebula in Andromeda, M31",
    },
    {
      imageId: "heaoBE",
      image: "/imageList/heaoB/HeaoBCrabNebula.png",
      imageCredit: "NASA/Marshall Space Flight Center",
      imageDesc: "X-ray image of the Crab Nebula demonstrated by a pulsar",
    },
    {
      imageId: "heaoBF",
      image: "/imageList/heaoB/HeaoBQuasar.png",
      imageCredit: "NASA/Marshall Space Flight Center",
      imageDesc: "Quasar 3C 273 about 10 billion light years away",
    },
    {
      imageId: "heaoBG",
      image: "/imageList/heaoB/HeaoBTychosSupernova.png",
      imageCredit: "NASA/Marshall Space Flight Center",
      imageDesc: "Tycho's Supernova in the constellation Cassiopeia",
    },
    {
      imageId: "heaoBH",
      image: "/imageList/heaoB/cloop.png",
      imageCredit: "NASA",
      imageDesc: "Cloop",
    },
    {
      imageId: "heaoBI",
      image: "/imageList/heaoB/hyades.png",
      imageCredit: "NASA",
      imageDesc: "Hyades",
    },
    {
      imageId: "heaoBJ",
      image: "/imageList/heaoB/perseus.png",
      imageCredit: "NASA",
      imageDesc: "Perseus",
    },
    {
      imageId: "heaoBK",
      image: "/imageList/heaoB/sn.png",
      imageCredit: "NASA",
      imageDesc: "Supernova",
    },
  ],
  recentNews: [
    {
      newsId: "",
      image: "",
      title: "",
      summary: "",
    },
  ],
};
