import { IconButton, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";

import { useState } from "react";

export function Menus({ endIcon, isIcon, label, children }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {isIcon ? (
        <>
          <IconButton
            id="app-button"
            variant="outlined"
            aria-controls={open ? "app-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MenuIcon color="white" />
          </IconButton>

          <Menu
            id="app-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "app-button",
            }}
          >
            <MenuItem onClick={handleClose} component="a" href="/telescopes">
              Telescopes
            </MenuItem>
            <MenuItem onClick={handleClose} component="a" href="/news">
              News
            </MenuItem>
            <MenuItem onClick={handleClose} component="a" href="/resources">
              Resources
            </MenuItem>
          </Menu>
        </>
      ) : (
        <>
          <Button
            id="news-button"
            aria-haspopup="true"
            onClick={handleClick}
            endIcon={endIcon}
            // sx={{ ml: 8, mt: 2 }}
          >
            {label}
          </Button>
          <Menu
            id="news-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {children}
          </Menu>
        </>
      )}
    </div>
  );
}
