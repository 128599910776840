import { Box, Divider, Grid, Stack } from "@mui/material";
import { SortSelect } from "../../select/SortSelect";
import { StyledHeaderTitle } from "../../styles/StylesCheckboxes";
import { ShowOnlySmall } from "../../updates/ShowBoxes";
import { MissionStatusFilter } from "../StatusCheckboxes";
import { MissionTypeCheckboxes } from "../TypesCheckboxes";

export const SmallFilter = () => {
  return (
    <ShowOnlySmall>
      <Stack direction="row" justifyContent="space-evenly" flexWrap="wrap">
        <Grid item sx={5}>
          <Box
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.12)",
              mt: 3,
              p: 2,
              mb: 1,
            }}
          >
            <StyledHeaderTitle variant="h6">Sort Missions</StyledHeaderTitle>

            <Stack>
              <SortSelect />
            </Stack>
          </Box>

          <Box sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", mt: 3, p: 2 }}>
            <MissionStatusFilter />
          </Box>
        </Grid>

        <Grid item xs={5}>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            sx={{ pb: 4 }}
          >
            <Box sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", mt: 3, p: 2 }}>
              <StyledHeaderTitle variant="h6">Mission Types</StyledHeaderTitle>

              <Box display="grid" py={1}>
                <Divider flexItem variant="medium" alignSelf="center" />
              </Box>

              <MissionTypeCheckboxes />
            </Box>
          </Box>
        </Grid>
      </Stack>
    </ShowOnlySmall>
  );
};
