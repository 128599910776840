import { Box, Grid, Typography } from "@mui/material";
// import solar from "../../sol7.png";
import logoLil from "../../telescope.png";

export function WideLogoCover() {

    return (
    <Box
      sx={{
        middle: 0,
        position: "absolute",

        top: "5px",
        left: "25px",
      }}
    >
      <Box display="flex" fontSize="large" flexGrow={1}>
        <Grid container justifyContent="center">
          <Box 
          display="center"
          justifyContent="center"
          alignSelf="center" 
          pr={2}
          >
            <img
              src={logoLil}
              alt="badge"
              style={{
                width: "42px",
                height: "42px",
                mt: 4,
                display: "flex",
                justifyContent: "center"
              }}
            />
          </Box>
          <Box
                display="center"
                justifyContent="center"
                alignSelf="center"
                pr={2}
              >

          <Typography
            sx={{
              fontSize: "2.5em",
              color: "#fbfbfb",
              fontWeight: 900,
              fontFamily: "Wire One",
              letterSpacing: 1,
            }}
          >
            Tele
          </Typography>

          <Typography
            sx={{
              fontSize: "2.5em",
              color: "#cdcdcd",
              fontWeight: 500,
              fontFamily: "Wire One",
              letterSpacing: 1,
            }}
          >
            scoops
          </Typography>
          </Box>
        </Grid>
      </Box>
    </Box>
  ) 
}
