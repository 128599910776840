import { Box, Button, Grid, Typography } from "@mui/material";

export function ImageTextSmall() {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Grid container justifyContent="center">
        <Grid item alignItems="center">
          <Typography
            variant="h6"
            fontSize="small"
            sx={{ color: "white", fontWeight: 900 }}
          >
            Bringing you the scoop on space
          </Typography>
          
          <Grid item>
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button href="/telescopes" size="small" sx={{ fontSize: "10px" }}>
                Explore
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
