import { Button, CardMedia, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useRecoilValue } from "recoil";
import { getAllTelescopesInfo } from "../../data/telescope";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, IconButton, Paper } from "@mui/material";
import { useRef } from "react";

export function ButtonSlider() {
  const telescopes = useRecoilValue(getAllTelescopesInfo);

  const containerRef = useRef();

  const scrollLeft = (value) => {
    containerRef.current.scrollTo({
      left: containerRef.current.scrollLeft + value,
      behavior: "smooth",
    });
  };

  return (
    <Paper
      sx={{
        display: "flex",
        bgcolor: "#bfbfbf",
        alignItems: "center"
      }}
    >
      <IconButton onClick={() => scrollLeft(-200)}>
        <ArrowBackIosIcon />
      </IconButton>

      <Box ref={containerRef} display="flex" pt={5} pb={4} overflow="scroll">
      {telescopes.map((item) => (
        <Box
          key={item.id}
          sx={{
            flexDirection: "column",
            width: "130px",
          }}
        >
          <Stack width="130px">
            <Button variant="text" href={`/telescope/${item.id}`}>
              <CardMedia
                component="img"
                image={item.src}
                title={item.title}
                height="60px"
              />
            </Button>

            <Typography variant="button" color="#313234" textAlign="center">
              {item.button}
            </Typography>
          </Stack>
        </Box>
      ))}
      </Box>

      <IconButton onClick={() => scrollLeft(200)}>
        <ArrowForwardIosIcon />
      </IconButton>
    </Paper>
  );
}
