import { atom } from "recoil";
import { data } from "./data";

export const app = atom({
  key: "app",
  default: data,
});

export const gridViewMode = atom({
  key: "gridViewMode",
  default: true,
});

// export const appProjects = atom({
//   key: "appProjects",
//   default: appSections,
// });

// export const getMissions = selectorFamily({
//   key: "getProject",
//   get:
//     (missionId) =>
//     ({ get }) =>
//       get(appProjects).find((item) => item.missionId === missionId),
// });

// export const missionFilters = atomFamily({
//   key: "missionFilters",
//   default: [],
//   get: (missionId) => () => [],
// });




// export const missionTypesFilters = atomFamily({
//   key: "missionTypesFilters",
//   default: [],
//   get: (missionId) => () => [],
// });