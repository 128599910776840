export const galileo = {
  id: "galileo",
  title: "Galileo",
  launchDate: "October 18, 1989",
  endDate: "September 21, 2003",
  src: "/images/galileo.png",
  credit: "credit image",
  button: "Galileo",
  missionStatus: "Past",
  missionType: "orbital",
  launchVehicle: "Atlantis",
  launchSite: "Kennedy Space Center, Fla.",
  keyFact: [
    "First flyby and imaging of an asteroid (Gaspra, and later, Ida)",
    "First, and so far only, direct observation of a comet colliding with a planet's atmosphere (Shoemaker-Levy 9).    ",
  ],
  desc: {
    descId: "galileoInfo",
    headline: "Galileo was the first spacecraft to go into orbit around any outer planet",
    mainDesc:
      "Galileo orbited Jupiter for almost 8 years and made close passes by all of its major moons. Data collected show that Jupiter’s icy moon Europa probably has a subsurface ocean with more water than the total amount found on Earth. Also, that the volcanoes of the moon Io repeatedly and rapidly resurface the little world.  Galileo carried a small probe that it deployed and sent deep into the atmosphere of Jupiter, taking readings for 57 minutes before the atmospheric heat and pressure destroyed it.",
    subDesc: "",
  },

  images: [
    {
      imageId: "galileoA",
      image: "/imageList/gal/europaSurface.png",
      imageCredit: "NASA/JPL-Caltech/SETI Institute",
      imageDesc: "Surface of Jupiter's icy moon Europa",
    },
    {
      imageId: "galileoB",
      image: "/imageList/gal/callisto.png",
      imageCredit: "NASA/JPL/DLR",
      imageDesc: "Jupiter's moon Callisto",
    },
    {
      imageId: "galileoC",
      image: "/imageList/gal/terrainGanymede.png",
      imageCredit: "NASA/JPL/Brown University",
      imageDesc:
        "Terrain within Nicholson Regio, near the border with Harpagia Sulcus on Jupiter's moon Ganymede",
    },
    {
      imageId: "galileoD",
      image: "/imageList/gal/chainCraters.png",
      imageCredit: "NASA/JPL/University of Arizona",
      imageDesc:
        "12 image mosaic covering Io's Chain of Craters from the Chaac Patera to the Camaxtli Patera",
    },
  ],
  recentNews: [
    {
      newsId: "",
      image: "",
      title: "",
      summary: "",
    },
  ],
};
