export const lunar = {
  id: "lunarOrbit",
  title: "Lunar Reconnaissance Orbiter",
  launchDate: "June 18, 2019",
  endDate: "",
  src: "/images/lunarOrbiter.png",
  credit: "",
  button: "Lunar Orbiter",
  missionStatus: "active",
  missionType: "orbital",
  launchVehicle: "Atlas V",
  launchSite: "	Cape Canaveral, Fl",
  keyFact: ["Studied the Moon with a high-powered camera creating a 3D map"],
  desc: {
    descId: "",
    headline: "",
    mainDesc: "",
    subDesc: "",
  },

  images: [
    {
      imageId: "",
      image: "",
      imageCredit: "",
      imageDesc: "",
    },

    {
      imageId: "",
      image: "",
      imageCredit: "",
      imageDesc: "",
    },

    {
      imageId: "",
      image: "",
      imageCredit: "",
      imageDesc: "",
    },

    {
      imageId: "",
      image: "",
      imageCredit: "",
      imageDesc: "",
    },
  ],
  recentNews: [
    {
      newsId: "",
      image: "",
      title: "",
      summary: "",
    },
  ],
};
