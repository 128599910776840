export const bepiColombo = {
  id: "bepi",
  title: "BepiColombo",
  launchDate: "October 20, 2018",
  endDate: "",
  src: "/images/bepiColombome.png",
  credit: "",
  button: "BepiColombo",
  missionStatus: "active",
  missionType: "flyby",
  launchVehicle: "	Ariane 5 ECA",
  launchSite: "	Centre Spatial Guyanais, French Guiana",
  keyFact: [
    "Scheduled to reach Mercury by 2025, BepiColombo made its first flyby of Mercury on Oct. 1, 2021, and sent back several images.",
    "Several more planetary flybys will be used to steer BepiColombo into orbit around Mercury in December 2025",
  ],

  desc: {
    descId: "bepiDesc",
    headline:
      "Third spacecraft to visit Mercury, set to reach it's destination in 2025",
    mainDesc:
      "BepiColombo is an international mission comprised of two spacecraft riding together to Mercury  The European Space Agency (ESA) and the Japan Aerospace Exploration Agency (JAXA) each have an orbiter onboard. ESA built the main spacecraft, the Mercury Planetary Orbiter (MPO), and JAXA supplied the Mercury Magnetospheric Orbiter (MIO). MPO will study the surface and internal composition of the planet, and MIO will study Mercury's magnetosphere – the region of space around the planet that is dominated by its magnetic field.",
    subDesc: "",
  },

  images: [
    {
      imageId: "bepiA",
      image:
        "/imageList/bepi/Look__New_image_of_Venus_released_from_double_spacecraft_flyby.png",
      imageCredit: "ESA",
      imageDesc:
        "The first image from the 2020 flyby of Venus by the BepiColombo Mercury mission",
    },

    {
      imageId: "",
      image: "",
      imageCredit: "",
      imageDesc: "",
    },

    {
      imageId: "",
      image: "",
      imageCredit: "",
      imageDesc: "",
    },

    {
      imageId: "",
      image: "",
      imageCredit: "",
      imageDesc: "",
    },
  ],
  recentNews: [
    {
      newsId: "",
      image: "",
      title: "",
      summary: "",
    },
  ],
};
