import { Box, Divider } from "@mui/material";
import { NewsSideBar } from "../../../Pages/News/TelescopesNewsSideBar";

import { SortSelect } from "../../select/SortSelect";
import { StyledHeaderTitle } from "../../styles/StylesCheckboxes";
import { ShowLg } from "../../updates/ShowBoxes";
import { MissionStatusFilter } from "../StatusCheckboxes";
import { MissionTypeCheckboxes } from "../TypesCheckboxes";

export const LgFilter = () => {
  return (
    <ShowLg>
      <Box sx={{ border: "1px solid rgba(0, 0, 0, 0.12)" }}>
        <>
          <Box p={3}>
            <StyledHeaderTitle variant="h6">Sort Missions</StyledHeaderTitle>

            <SortSelect />
          </Box>

          <Divider sx={{ my: 2 }} />
          <Box p={3}>
            <MissionStatusFilter isSidebar />
          </Box>
          <Divider sx={{ my: 2 }} />

          <Box p={3}>
            <StyledHeaderTitle variant="h6">Browse Missions</StyledHeaderTitle>
            <Divider sx={{ my: 2 }} />

            <MissionTypeCheckboxes />
          </Box>
        </>
      </Box>
      <NewsSideBar />
    </ShowLg>
  );
};
