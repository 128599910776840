import { Box, Button, Grid, Typography } from "@mui/material";

export function ImageTextLarge() {
  return (
    <Box
      sx={{
        middle: 0,
        position: "absolute",
        bottom: "5em",
        right: "10em",
      }}
    >
      <Grid container>
        <Grid item>
          <Typography
            variant="h4"
            fontSize="large"
            sx={{ color: "white", fontWeight: 900 }}
          >
            Bringing you the scoop on space
          </Typography>

          <Grid item>
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button
                href="/telescopes"
                size="small"
              >
                Explore Telescopes
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
