import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { excludedNewsIds, getAllNewsData } from "../../data/telescope";
import { FormCheckboxes } from "./FormCheckboxes";

export function NewsCheckboxes() {
  const [newsIds, setNewsIds] = useRecoilState(excludedNewsIds);
  const checkList = useRecoilValue(getAllNewsData).map((item) => ({
    id: item.telescopeId,
    name: item.telescopeTitle,
  }));

  const handleStateChange = (event, value) => {
    const name = event.target.name;
    const newValues =
      value !== true
        ? [...newsIds, name]
        : newsIds.filter((item) => item !== name);

    setNewsIds(newValues);
  };

  let checkListLabel = [];
  checkList.forEach((c) => {
    if (!checkListLabel.includes(c.name)) {
      checkListLabel.push(c.name);
    }
  });

  let checkListId = [];
  checkList.forEach((c) => {
    if (!checkListId.includes(c.id)) {
      checkListId.push(c.id);
    }
  });

  var isLabel = checkListLabel;
  var isId = checkListId;

  var output = isId.map((e, i) => [e, isLabel[i]]);

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            onChange={(e, value) => {
              setNewsIds(
                value !== true ? checkList.map((item) => item.telescopeId) : []
              );
            }}
            checked={!newsIds.length}
          />
        }
        label="All"
      />
      {output.map(([id, name]) => (
        <FormCheckboxes
          name={id}
          // type="checkbox"
          onChange={handleStateChange}
          checked={!newsIds.includes(id)}
          label={name}
        />
      ))}
    </FormGroup>
  );
}
