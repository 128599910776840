import {
  Box,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { SortSelect } from "../../select/SortSelect";
import { StyledHeaderTitle } from "../../styles/StylesCheckboxes";
import { MissionStatusFilter } from "../StatusCheckboxes";
import { MissionTypeCheckboxes } from "../TypesCheckboxes";

export function FilterCollapse({ label, children }) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List disablePadding>
      <ListItemButton onClick={handleClick}>
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            //   fontSize: {fontSize},
            color: "primary",
            variant: "button",
          }}
        />
        {open ? (
          <KeyboardArrowUpIcon color="primary" />
        ) : (
          <KeyboardArrowDownIcon color="primary" />
        )}
      </ListItemButton>
      <Divider />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <>
          <Box p={3}>
            <StyledHeaderTitle variant="h6">Sort Missions</StyledHeaderTitle>

            <SortSelect />
          </Box>

          <Divider sx={{ my: 2 }} />
          <Box p={3}>
            <MissionStatusFilter isSidebar />
          </Box>
          <Divider sx={{ my: 2 }} />

          <Box p={3}>
            <StyledHeaderTitle variant="h6">Browse Missions</StyledHeaderTitle>
            <Divider sx={{ my: 2 }} />

            <MissionTypeCheckboxes />
          </Box>
        </>
      </Collapse>
    </List>
  );
}

export function FilterSortCollapse({ label }) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List disablePadding>
      <ListItemButton onClick={handleClick}>
        <ListItemText
          primary="Sort"
          primaryTypographyProps={{
            //   fontSize: {fontSize},
            color: "primary",
            variant: "button",
          }}
        />
        {open ? (
          <KeyboardArrowUpIcon color="primary" />
        ) : (
          <KeyboardArrowDownIcon color="primary" />
        )}
      </ListItemButton>
      <Divider />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box p={3}>
          <StyledHeaderTitle variant="h6">Sort Missions</StyledHeaderTitle>

          <SortSelect />
        </Box>
      </Collapse>
    </List>
  );
}

export function FilterStatusCollapse({ label }) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List disablePadding>
      <ListItemButton onClick={handleClick}>
        <ListItemText
          primary="Filter Status"
          primaryTypographyProps={{
            //   fontSize: {fontSize},
            color: "primary",
            variant: "button",
          }}
        />
        {open ? (
          <KeyboardArrowUpIcon color="primary" />
        ) : (
          <KeyboardArrowDownIcon color="primary" />
        )}
      </ListItemButton>
      <Divider />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box p={3}>
          <MissionStatusFilter isSidebar />
        </Box>
      </Collapse>
    </List>
  );
}

export function FilterTypesCollapse({ label }) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List disablePadding>
      <ListItemButton onClick={handleClick}>
        <ListItemText
          primary="Browse Types"
          primaryTypographyProps={{
            //   fontSize: {fontSize},
            color: "primary",
            variant: "button",
          }}
        />
        {open ? (
          <KeyboardArrowUpIcon color="primary" />
        ) : (
          <KeyboardArrowDownIcon color="primary" />
        )}
      </ListItemButton>
      <Divider />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box p={3}>
          <StyledHeaderTitle variant="h6">Browse Missions</StyledHeaderTitle>
          <Divider sx={{ my: 2 }} />

          <MissionTypeCheckboxes />
        </Box>
      </Collapse>
    </List>
  );
}
