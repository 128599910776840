import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import {
  Box,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";

import { StyledHeaderTitle } from "../../App/styles/StylesCheckboxes";
import { NoShowXSmall, ShowXSmall } from "../../App/updates/ShowBoxes";
import { SpaceAppBar } from "../../AppBar/AppBar";
import { links } from "./resourceData";

export function LinkList() {
  return (
    <>
      <SpaceAppBar />

      <Grid container sx={{ justifyContent: "center" }}>
        <Grid item xs={9}>
          <StyledHeaderTitle variant="h6" mt={4} ml={4}>
            Resources
          </StyledHeaderTitle>

          <List>
            {links.map((item, index) => (
              <ul>
                <ListItem
                  key={item.link}
                  disablePadding
                  // divider
                  sx={{ justifyContent: "space-between", flexWrap: "wrap" }}
                >
                  <Link href={item.link} target="_blank">
                    <ListItemText
                      primary={item.text}
                      secondary={item.link}
                      sx={{ fontSize: "large" }}
                    />
                  </Link>

                  <Box sx={{ alignItems: "flex-end" }}>
                    <ListItemButton
                      component="a"
                      href={item.link}
                      target="_blank"
                    >
                      <NoShowXSmall>view more</NoShowXSmall>

                      <ShowXSmall>
                        <ExitToAppIcon />
                      </ShowXSmall>
                    </ListItemButton>
                  </Box>
                </ListItem>
                {index !== links.length - 1 ? <Divider sx={{ mt: 2 }} /> : null}
              </ul>
            ))}
          </List>
        </Grid>
      </Grid>
    </>
  );
}
