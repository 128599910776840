import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import PagesIcon from "@mui/icons-material/Pages";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { useMediaQuery } from "../../App/MediaQuery";
import {
  StyledNewsCloseButtonBox,
  StyledNewsMedia,
} from "../../App/styles/StyledTelescopeGridCard";
import { getActiveNewsInfo } from "../../data/telescope";

export function ActiveNewsDialog({ onClick }) {
  const isPageWide = useMediaQuery("(min-width: 700px)");
  const [activeNewsInfo, setActiveNewsInfo] = useRecoilState(getActiveNewsInfo);

  return (
    <>
      <Button
        size="small"
        variant="text"
        color="secondary"
        component={Link}
        endIcon={<ArrowForwardIosIcon />}
        onClick={onClick}
      >
        read more
      </Button>

      {activeNewsInfo && (
        <Dialog
          open
          onClose={() => setActiveNewsInfo(undefined)}
          aria-labelledby="news-modal"
          aria-describedby="news-modal-description"
          fullScreen
          scroll="body"
        >
          <Card
            key={activeNewsInfo?.newsId}
            sx={{
              p: 3,
              alignItems: "stretch",
              alignSelf: "center",
            }}
            elevation={0}
          >
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <StyledNewsMedia
                  component="img"
                  image={activeNewsInfo.image}
                  alt={activeNewsInfo.imageCredit}
                />
                <Typography variant="caption">
                  {activeNewsInfo.imageCredit}
                </Typography>
              </Grid>

              <StyledNewsCloseButtonBox>
                <IconButton
                  variant="contained"
                  size="large"
                  onClick={() => setActiveNewsInfo(undefined)}
                  sx={{
                    alignContent: "center",
                    color: "primary",
                    p: 2,
                    mr: 1,
                  }}
                >
                  <CloseIcon color="primary" />
                </IconButton>
              </StyledNewsCloseButtonBox>

              <CardContent sx={{ width: "80em" }}>
                <Typography variant="h5">{activeNewsInfo.title}</Typography>

                <Divider />

                <Typography
                  variant="subtitle2"
                  component="div"
                  textAlign="flex-start"
                  pr={7}
                  pt={1}
                >
                  {activeNewsInfo.summary}
                </Typography>

                {isPageWide ? (
                  <CardActions
                    sx={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      endIcon={<ArrowCircleRightIcon fontSize="large" />}
                      variant="text"
                      href={activeNewsInfo.link}
                      target="_blank"
                      sx={{ pl: 5, pr: 5 }}
                    >
                      Link to Article
                    </Button>

                    <Button
                      endIcon={<PagesIcon />}
                      variant="text"
                      component="a"
                      href={`/telescope/${activeNewsInfo.telescopeId}`}
                    >
                      View Scope
                    </Button>

                    <Button
                      endIcon={<CloseIcon />}
                      size="small"
                      onClick={() => setActiveNewsInfo(undefined)}
                      sx={{ pb: 2 }}
                    >
                      Close
                    </Button>
                  </CardActions>
                ) : (
                  <CardActions sx={{ justifyContent: "space-between" }}>
                    <Button
                      endIcon={<ArrowCircleRightIcon fontSize="small" />}
                      variant="text"
                      size="small"
                      href={activeNewsInfo.link}
                      target="_blank"
                      // sx={{ pl: 5, pr: 5 }}
                      sx={{ fontSize: "12px" }}
                    >
                      Link to Article
                    </Button>

                    <Button
                      endIcon={<PagesIcon />}
                      variant="text"
                      size="small"
                      component="a"
                      href={`/telescope/${activeNewsInfo.telescopeId}`}
                      sx={{ fontSize: "12px" }}
                    >
                      View Scope
                    </Button>

                    <Button
                      endIcon={<CloseIcon />}
                      size="small"
                      variant="text"
                      onClick={() => setActiveNewsInfo(undefined)}
                      sx={{ pb: 2, fontSize: "12px" }}
                    >
                      Close
                    </Button>
                  </CardActions>
                )}
              </CardContent>
            </Grid>
          </Card>
        </Dialog>
      )}
    </>
  );
}
