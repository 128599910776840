import { Box } from "@mui/material";

// show boxes
export function NoShowXSmall({ children }) {
  return (
    <Box
      sx={{
        display: {
          xs: "none",
          sm: "block",
          md: "block",
          lg: "block",
          xl: "block",
        },
      }}
    >
      {children}
    </Box>
  );
}

export function NoShowSmall({ children }) {
  return (
    <Box
      sx={{
        display: {
          xs: "none",
          sm: "none",
          md: "block",
          lg: "block",
          xl: "block",
        },
      }}
    >
      {children}
    </Box>
  );
}

export function ShowXSmall({ children }) {
  return (
    <Box
      sx={{
        display: {
          xs: "block",
          sm: "none",
          md: "none",
          lg: "none",
          xl: "none",
        },
      }}
    >
      {children}
    </Box>
  );
}

export function ShowSmall({ children }) {
  return (
    <Box
      sx={{
        display: {
          xs: "block",
          sm: "block",
          md: "none",
          lg: "none",
          xl: "none",
        },
      }}
    >
      {children}
    </Box>
  );
}

export function ShowOnlySmall({ children }) {
  return (
    <Box
      sx={{
        display: {
          xs: "none",
          sm: "block",
          md: "none",
          lg: "none",
          xl: "none",
        },
      }}
    >
      {children}
    </Box>
  );
}

export function ShowOnlyXsMd({ children }) {
  return (
    <Box
      sx={{
        display: {
          xs: "block",
          sm: "none",
          md: "block",
          lg: "none",
          xl: "none",
        },
      }}
    >
      {children}
    </Box>
  );
}

export function ShowOnlyMd({ children }) {
  return (
    <Box
      sx={{
        display: {
          xs: "none",
          sm: "none",
          md: "block",
          lg: "none",
          xl: "none",
        },
      }}
    >
      {children}
    </Box>
  );
}

export function ShowSmallMd({ children }) {
  return (
    <Box
      sx={{
        display: {
          xs: "none",
          sm: "block",
          md: "block",
          lg: "none",
          xl: "none",
        },
      }}
    >
      {children}
    </Box>
  );
}

export function ShowMd({ children }) {
  return (
    <Box
      sx={{
        display: {
          xs: "block",
          sm: "block",
          md: "block",
          lg: "none",
          xl: "none",
        },
      }}
    >
      {children}
    </Box>
  );
}

export function ShowLg({ children }) {
  return (
    <Box
      sx={{
        display: {
          xs: "none",
          sm: "none",
          md: "none",
          lg: "block",
          xl: "block",
        },
      }}
    >
      {children}
    </Box>
  );
}
