export const veritas = {
  id: "veritas",
  title: "VERITAS",
  launchDate: "2028 - 2030",
  endDate: "",
  src: "/images/veritas.png",
  credit: "",
  button: "Veritas",
  missionStatus: "planned",
  missionType: "orbital",
  launchVehicle: "Atlas V",
  launchSite: "",
  keyFact: ["Map out Venus' topography"],
  desc: {
    descId: "",
    headline: "Veritas will orbit Venus and map out the planets topography",
    mainDesc: "",
    subDesc: "",
  },

  // images: [
  //   {
  //     imageId: "",
  //     image: "",
  //     imageCredit: "",
  //     imageDesc: "",
  //   },

  //   {
  //     imageId: "",
  //     image: "",
  //     imageCredit: "",
  //     imageDesc: "",
  //   },

  //   {
  //     imageId: "",
  //     image: "",
  //     imageCredit: "",
  //     imageDesc: "",
  //   },

  //   {
  //     imageId: "",
  //     image: "",
  //     imageCredit: "",
  //     imageDesc: "",
  //   },
  // ],
  recentNews: [
    {
      newsId: "",
      image: "",
      title: "",
      summary: "",
    },
  ],
};
