import ZoomInIcon from "@mui/icons-material/ZoomIn";
import {
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import { useState } from "react";
import ReactBnbGallery from "react-bnb-gallery";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getTelescopeInfo } from "../../data/telescope";

export function TelescopeImagesList({
  telescopeIdToUse,
  options,
  cols,
  width,
}) {
  const { telescopeId = telescopeIdToUse } = useParams();

  const [openImage, setOpenImage] = useState();

  const telescope = useRecoilValue(getTelescopeInfo(telescopeId));

  const telescopeImages = telescope.images || [];

  const foundIndex = telescopeImages.findIndex(
    ({ imageId }) => imageId === openImage?.imageId
  );

  return (
    <>
      <ImageList
        cols={cols}
        rowHeight={350}
        gap={1}
        sx={{ width: { width }, height: 720 }}
      >
        {options.map((image) => (
          <ImageListItem key={image.imageId} sx={{ height: "300" }}>
            <img
              src={`${image?.image}?w=248&fit=crop&auto=format`}
              srcSet={`${image?.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={image.imageId}
              onClick={() => setOpenImage(image)}
              loading="lazy"
              style={{ borderRadius: "5px", height: "300" }}
            />

            <ImageListItemBar
              position="top"
              actionIcon={
                <IconButton onClick={() => setOpenImage(image)}>
                  <ZoomInIcon sx={{ color: "rgba(255, 255, 255, 0.54)" }} />
                </IconButton>
              }
              actionPosition="left"
              sx={{ background: "#00000000" }}
            />
          </ImageListItem>
        ))}
      </ImageList>

      <ReactBnbGallery
        show={!!openImage}
        activePhotoIndex={foundIndex === -1 ? 0 : foundIndex}
        onClose={() => setOpenImage(undefined)}
        preloadSize={8}
        photos={telescopeImages.map((image) => ({
          photo: image.image,
          caption: image.imageDesc,
          subcaption: image.imageCredit,
        }))}
      />
    </>
  );
}


