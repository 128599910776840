export const spitzer = {
  id: "spitzer",
  title: "Spitzer Telescope",
  button: "Spitzer",
  launchDate: "August 25, 2003",
  endDate: "January 30, 2020",
  src: "/images/spitzer.png",
  credit: "credit image",
  missionStatus: "Past",
  missionType: "orbital",
  launchVehicle: "Delta 7920H",
  launchSite: "Cape Canaveral, Fla.",
  keyFact: [
    "Captured direct light from extrasolar planets for the first time",
    "Discovered a giant ring of Saturn",
    "Gathered more precise measurement of the Hubble constant",
    "Discovered the rate at which the universe is stretching apart",
    "Revealed the first known system of seven Earth-size planets around a single star, This discovery set a record for the greatest number of habitable-zone planets found around a single star outside our solar system",
  ],
  desc: {
    descId: "spitzerInfo",
    headline:
      "The Spitzer Space Telescope was the final mission in NASA's Great Observatories Program - a family of four space-based observatories, each observing the Universe in a different kind of light Spitzer’s being infrared.",
    mainDesc:
      "The Spitzer Space Telescope is the fourth and final mission in NASA's Great Observatories Program - a family of four space-based observatories, each observing the Universe in a different kind of light. It  was the most sensitive infrared space telescope in history when it launched.  Spitzer orbits the Sun on almost the same path as Earth. It moves slower than Earth though, so the spacecraft drifts farther away from our planet each year.",
    subDesc:
      "Spitzer Space Telescope’s highly sensitive instruments allowed scientists a view into dusty stellar nurseries, the centers of galaxies, and newly forming planetary systems. Using Spitzer astronomers also have a view of cooler objects in space, like failed stars (brown dwarfs), extrasolar planets, giant molecular clouds, and organic molecules that may hold the secret to life on other planets.",
  },

  images: [
    {
      imageId: "spitzA",
      image: "/imageList/spitzer/SpitzerCassiopeiaA.png",
      imageCredit: "NASA/JPL-Caltech/STScI/CXC/SAO",
      imageDesc:
        "This supernova remnant is located 10,000 light-years away in the constellation Cassiopeia",
    },
    {
      imageId: "spitzB",
      image: "/imageList/spitzer/Spitzermilkywqy.png",
      imageCredit: "NASA, JPL-Caltech, Susan Stolovy (SSC/Caltech) et al",
      imageDesc:
        "Spitzer's infrared cameras penetrate much of the dust, revealing the stars of a crowded galactic center region",
    },
    {
      imageId: "spitzC",
      image: "/imageList/spitzer/SpitzerRingStellarFire.png",
      imageCredit: "NASA/JPL-Caltech",
      imageDesc: " NGC 1291, Galaxy located in the Eridanus constellation",
    },
    {
      imageId: "spitzD",
      image: "/imageList/spitzer/orion.png",
      imageCredit: " NASA/JPL-Caltech/S.T. Megeaty (Univ. of Toledo,OH)",
      imageDesc:
        "Orion nebula, the closest massive star-making factory to Earth",
    },
    {
      imageId: "spitzI",
      image: "/imageList/spitzer/andromeda.png",
      imageCredit: "NASA/JPL-Caltech/K. Gordon (University of Arizona)",
      imageDesc: "Andromeda galaxy",
    },
    {
      imageId: "spitzG",
      image: "/imageList/spitzer/gasCloud.png",
      imageCredit: "NASA/JPL-Caltech",
      imageDesc:
        "nebula – a cloud of gas and dust in space. Colors (blue, cyan, green, and red) representing different wavelengths of infrared light. Blue and cyan represent wavelengths primarily emitted by stars; dust and organic molecules called hydrocarbons appear green; and warm dust that’s been heated by stars or supernovae appears red. Yellow and White are combinations wavelengths",
    },
    {
      imageId: "spitzE",
      image: "/imageList/spitzer/spitzerstellarsnowflake.png",
      imageCredit: "NASA-ESA/STScI/AURA/JPL-Caltech",
      imageDesc: "Arp 142, distant interacting galaxies",
    },
    {
      imageId: "spitzF",
      image: "/imageList/spitzer/antennae.png",
      imageCredit:
        "NASA/CXC/SAO/J.DePasquale; IR: NASA/JPL-Caltech; Optical: NASA/STScI",
      imageDesc:
        "The Antennae galaxies, located about 62 million light years from Earth, are shown in this composite image from the Chandra X-ray Observatory (blue), the Hubble Space Telescope (gold and brown), and the Spitzer Space Telescope (red).",
    },
    {
      imageId: "spitzG",
      image: "/imageList/spitzer/helix.png",
      imageCredit: "NASA/JPL-Caltech/Univ. of Arizona",
      imageDesc: "Infrared image of the Helix Nebula",
    },
    {
      imageId: "spitzH",
      image: "/imageList/spitzer/darkGlob.png",
      imageCredit: "NASA/JPL-Caltech/W. Reach (SSC/Caltech)",
      imageDesc: "Glowing stellar nursery within a dark globule in IC 1396 ",
    },
  ],

  recentNews: [
    {
      newsId: "godzilla",
      image: "/imageList/spitzer/monsterStar.png",
      imageCredit: "NASA/JPL-Caltech",
      month: "10",
      year: "2021",
      title: "Godzilla spotted in space",
      summary:
        "Caltech astronomer Robert Hurt , responsible for the majority of public images created from Spitzer data, spotted Godzilla in this image.  This colorful image shows a nebula – a cloud of gas and dust in space – captured by NASA’s Spitzer Space Telescope. Over billions of years, countless stars have formed in the material there. During their lifetimes, the radiation they release carves away the gas and dust, reshaping the cloud. Major changes also occur when massive stars die and explode, becoming supernovae. When viewed in visible light, the kind human eyes can detect, this region is almost entirely obscured by dust clouds. But infrared light (wavelengths longer than what our eyes can perceive) can penetrate the clouds, revealing hidden regions like this one. Four colors (blue, cyan, green, and red) are used to represent different wavelengths of infrared light; yellow and white are combinations of those wavelengths. Blue and cyan represent wavelengths primarily emitted by stars; dust and organic molecules called hydrocarbons appear green; and warm dust that’s been heated by stars or supernovae appears red.",
      link: "https://www.spitzer.caltech.edu/news/a-monster-star-forming-region-spied-by-nasa-s-spitzer",
    },
  ],
};
