export const webb = {
  id: "jamesWebb",
  title: "James Webb Space Telescope",
  launchDate: "December 25, 2020",
  endDate: " Active",
  isFavorite: true,
  src: "/images/jameswebb.png",
  credit: "credit image",
  button: "JWST",
  missionStatus: "active",
  missionType: "observatory",
  launchVehicle: "	Ariane 5 ECA ",
  launchSite: "	Centre Spatial Guyanais, French Guiana",
  keyFact: ["Largest telescope ever sent into space"],
  desc: {
    descId: "jamesWebbInfo",
    headline:
      "Reveal previously hidden regions of the universe. It will study early galaxies, forming planets, brown dwarfs and make observstiond of stars shrouded in clouds of dust, water in the atmospheres of other worlds, and the first light from the earliest galaxies ever formed",
    mainDesc:
      "This giant spacecraft could cover a typical tennis court with its sunshield.  Atop the sunshield is the largest primary mirror ever sent into space – some 6.5 meters (21 feet, 4 inches) across. Seeing the universe in infrared light, the Webb telescope is expected to become the premiere observatory of the decade, studying billions of years of the universe's history and reaching back nearly to the Big Bang.",
    subDesc:
      " It can reveal details of the formation of planetary systems like our own, and even sample (via the rainbow spectrum of captured light) the composition of exoplanet atmospheres.",
  },
  recentNews: [
    {
      newsId: "webbFirst",
      image: "/imageList/webb/firstImg.png",
      imageCredit: "NASA",
      title: "Star light, star bright - the first starlight images from Webb",
      month: "2",
      year: "2022",
      summary:
        "The first published image taken by the James Webb Space Telescope shows an image mosaic of 18 randomly organized dots of starlight created over 25 hours beginning on Feb. 2, 2022, during an ongoing process to align the observatory's segmented mirror. Over the next month, the team will gradually adjust the mirror segments until the 18 images become a single star. The first scientific images are expected to start being released this summer.",
      link: "https://www.space.com/james-webb-space-telescope-first-photos-unveiled",
    },
    {
      newsId: "webbTwo",
      image: "/imageList/webb/smallImg.png",
      imageCredit: "NASA",
      title: "Webb Alignment Complete",
      month: "4",
      year: "2022",
      summary:
        "The three-month process of aligning the James Webb Space Telescope's instruments and mirrors is finally complete, according to a NASA statement released today (April 28). The agency shared sample images",
      link: "https://www.space.com/news/live/james-webb-space-telescope-updates",
    },
    {
      newsId: "webbCount",
      image: "/imageList/webb/countdownWebb.png",
      imageCredit: "NASA, CSA, and FGS team",
      title: "Countdown to Webbs first images",
      month: "7",
      year: "2022",
      summary:
        "We're less than one week away from the July 12, 2022, release of the first science-quality images from NASA’s James Webb Space Telescope",
      link: "https://www.nasa.gov/image-feature/countdown-to-the-webb-telescopes-first-images",
    },
    {
      newsId: "webbDeep",
      image: "/imageList/webb/mainImageWebb.png",
      imageCredit: "NASA, CSA, and FGS team",
      title: "JWT First Deep Field",
      month: "7",
      year: "2022",
      summary:
        "Named Webb’s First Deep Field, this image has produced the deepest and sharpest infrared image of the distant universe to date. Thousands of galaxies – including the faintest objects ever observed in the infrared – have appeared in Webb’s view for the first time.",
      link: "https://www.nasa.gov/image-feature/goddard/2022/nasa-s-webb-delivers-deepest-infrared-image-of-universe-yet",
    },
    {
      newsId: "webbCol",
      image: "/imageList/webb/webbReveal.png",
      imageCredit: "",
      title: "James Webb Reveals Stunning Images",
      month: "7",
      year: "2022",
      summary:
        "First collection of images released from James Webb Space Telescope",
        link: "https://www.nasa.gov/webbfirstimages"
    },
  ],
  images: [
    {
      imageId: "webbD",
      image: "/imageList/webb/mainImageWebb.png",
      imageCredit: "NASA, ESA, CSA, and STScI",
      imageDesc:
        "Webb's First Deep Field, deepest and sharpest infared image of the distant universe to date",
    },
    {
      imageId: "webbA",
      image:
        "/imageList/webb/Humanity’s_Last_Glimpse_of_the_James_Webb_Space_Telescope___Flickr.png",
      imageCredit: "Arianespace, ESA, NASA, CSA, CNES",
      imageDesc:
        "Final look at the James Webb Space Telescope as it heads into deep space",
    },
    {
      imageId: "webbB",
      image: "/imageList/webb/firstImg.png",
      imageCredit: "NASA",
      imageDesc:
        "First images from the James Webb Space Telescope, a mosaic created during the ongoing 18 mirror segment alignment",
    },
    {
      imageId: "webbC",
      image: "/imageList/webb/galaxiesPeak.png",
      imageCredit: "NASA/STScI",
      imageDesc:
        "First finely-phased image ever released by NASA's James Webb Space Telescope shows a single image of a star with galaxies photo bombing in the background .",
    },
    {
      imageId: "webbE",
      image: "/imageList/webb/jwGalaxyGroup.png",
      imageCredit: " NASA, ESA, CSA, and STScI",
      imageDesc:
        "Stephan’s Quintet, a visual grouping of five galaxies. This enormous mosaic is Webb’s largest image to date, covering about one-fifth of the Moon’s diameter. Webb captures huge shock waves as one of the galaxies smashes through the cluster.",
    },
    {
      imageId: "webbF",
      image: "/imageList/webb/jwStellar.png",
      imageCredit: " NASA, ESA, CSA, and STScI",
      imageDesc:
        "Planetary nebula known informally as the Southern Ring Nebula sending clouds of gas and dust expelled by dying stars for thousands of years in all directions",
    },

    {
      imageId: "webbG",
      image: "/imageList/webb/jwCarina.png",
      imageCredit: " NASA, ESA, CSA, and STScI",
      imageDesc:
        "Cosmic Cliffs in the Carina Nebula. This cavernous area has been carved from the nebula by the intense ultraviolet radiation and stellar winds from extremely massive, hot, young stars.",
    },

    {
      imageId: "webbH",
      image: "/imageList/webb/webbgalacticmerg.png",
      imageCredit: "NASA, ESA, CSA, STScI",
      imageDesc:
        "A stunning smash-up of two spiral galaxies shines in infrared with the light of more than a trillion suns.",
    },

    {
      imageId: "webbI",
      image: "/imageList/webb/webbGalaxies.png",
      imageCredit: "NASA, ESA, CSA, Simon Lilly (ETH Zurich), Daichi Kashino (Nagoya University), Jorryt Matthee (ETH Zurich), Christina Eilers (MIT), Rob Simcoe (MIT), Rongmon Bordoloi (NCSU), Ruari Mackenzie (ETH Zurich)",
      imageDesc:
        "Set of galaxies that existed when the universe was only 900 million years old. Stars in these galaxies emitted enough light to ionize and heat the gas around them, forming huge, transparent “bubbles.” Eventually, those bubbles met and merged, leading to today’s clear and expansive views",
    },

    {
      imageId: "webbJ",
      image: "/imageList/webb/webbcass.png",
      imageCredit: "NASA, ESA, CSA, Danny Milisavljevic (Purdue University), Tea Temim (Princeton University), Ilse De Looze (UGent)",
      imageDesc:
        "Supernova remnant Cassiopeia A (Cas A), created by a stellar explosion 340 years ago from Earth's perspective.  Youngest known remnant from an exploding, massive star in our galaxy",
    },

    {
      imageId: "webbK",
      image: "/imageList/webb/webbrho.png",
      imageCredit: "NASA, ESA, CSA, STScI, Klaus Pontoppidan (STScI)",
      imageDesc:
        "Rho Ophiuchi cloud complex, the closest star-forming region to Earth.",
    },
  ],
};
