export const origins = {
  id: "origins",
  title: "Origins",
  launchDate: "2035",
  endDate: "",
  src: "/images/origins.png",
  credit: "telescoops",
  button: "Origins",
  missionStatus: "planned",
  missionType: "observatory",
  launchVehicle: "",
  launchSite: "",
  keyFact: [""],
  desc: {
    descId: "",
    headline: "Origins is designed to trace the history of our cosmic origins, from the formation of the first galaxies and the rise of heavy elements to the development of habitable worlds and present-day life",
    mainDesc: "",
    subDesc: "",
  },

  images: [
    {
      imageId: "",
      image: "",
      imageCredit: "",
      imageDesc: "",
    },

    {
      imageId: "",
      image: "",
      imageCredit: "",
      imageDesc: "",
    },

    {
      imageId: "",
      image: "",
      imageCredit: "",
      imageDesc: "",
    },

    {
      imageId: "",
      image: "",
      imageCredit: "",
      imageDesc: "",
    },
  ],
  recentNews: [
    {
      newsId: "",
      image: "",
      title: "",
      summary: "",
    },
  ],
};
