export const links = [
  { text: "Nasa Exoplanets", link: "https://exoplanets.nasa.gov" },
  { text: "Tess", link: "https://tess.mit.edu" },
  { text: "Nasa Spaceplace", link: "https://spaceplace.nasa.gov" },
  { text: "Space", link: "https://www.space.com" },
  {
    text: "Juno",
    link: "https://www.missionjuno.swri.edu",
  },
  {
    text: "Voyager",
    link: "https://voyager.jpl.nasa.gov",
  },
  {
    text: "Messenger",
    link: "https://messenger.jhuapl.edu",
  },
  {
    text: "Herschel",
    link: "https://www.herschel.caltech.edu",
  },
  {
    text: "Picture of the Day",
    link: "https://apod.nasa.gov",
  },
];
