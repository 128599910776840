import { AppBar, Box, Link, Toolbar } from "@mui/material";
import { useMediaQuery } from "../App/MediaQuery";
import solar from "../sol7.png";
import {
  StyledLogoEnd,
  StyledLogoStart,
} from "../App/styles/StyledTelescopeGridCard";
import { Menus } from "../App/updates/Menus";
// import logoLil from "../telescope.png";
import { AppBarLinks } from "./AppBarLinks";

export function SpaceAppBar() {
  const isWide = useMediaQuery("(min-width: 800px)");

  return (
    <Box
      sx={{
        display: "flex",
        pt: 0,
        mt: 0,
      }}
    >
      <AppBar position="static">
        <Toolbar>
          <Box display="flex" flexGrow={1}>
            <Box display="flex" justifyContent="center">
              <Link href={"/"} sx={{ alignSelf: "center", pr: 3 }}>
                <Box
                  display="center"
                  justifyContent="center"
                  alignSelf="center"
                >
                  <img
                    src={solar}
                    alt="badge"
                    style={{
                      width: "42px",
                      height: "42px",
                      mt: 4,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </Box>
              </Link>
              <Link href={"/"} sx={{ alignSelf: "center"}}>

              <Box
                display="center"
                justifyContent="center"
                alignSelf="center"
                pr={2}
              >
                <StyledLogoStart>Tele</StyledLogoStart>

                <StyledLogoEnd>scoops</StyledLogoEnd>
              </Box>
              </Link>
            </Box>
          </Box>

          {isWide ? (
            <AppBarLinks />
          ) : (
            <Box mr={7}>
              <Menus isIcon />
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
