export const davinci = {
  id: "davinci",
  title: "DAVINCI",
  launchDate: "2028 - 2030",
  endDate: "",
  src: "/images/davinci.png",
  credit: "",
  button: "Davinci",
  missionStatus: "planned",
  missionType: "orbital",
  launchVehicle: "Atlas V ",
  launchSite: "Florida",
  keyFact: ["Explore Venus' atmosphere and mysterious geological features"],
  desc: {
    descId: "venusDesc",
    headline: "Explore Venus' atmosphere and mysterious geological features",
    mainDesc: "",
    subDesc: "",
  },

  // images: [
  //   {
  //     imageId: "",
  //     image: "",
  //     imageCredit: "",
  //     imageDesc: "",
  //   },

  //   {
  //     imageId: "",
  //     image: "",
  //     imageCredit: "",
  //     imageDesc: "",
  //   },

  //   {
  //     imageId: "",
  //     image: "",
  //     imageCredit: "",
  //     imageDesc: "",
  //   },

  //   {
  //     imageId: "",
  //     image: "",
  //     imageCredit: "",
  //     imageDesc: "",
  //   },
  // ],
  // recentNews: [
  //   {
  //     newsId: "",
  //     image: "",
  //     title: "",
  //     summary: "",
  //   },
  // ],
};
