import { Box, Divider, FormControl, FormLabel, Grid } from "@mui/material";
import { LgFilter } from "../../App/checkboxes/filters/LgFilter";
import { MdFilter } from "../../App/checkboxes/filters/MdFilter";
import { SmallFilter } from "../../App/checkboxes/filters/SmallFilter";
import { XSmallFilter } from "../../App/checkboxes/filters/XSmallFilter";
import { NewsCheckboxes } from "../../App/checkboxes/NewsCheckboxes";
import { ScrollTop } from "../../App/ScrollTop";
import { StyledBorderBox } from "../../App/styles/StyledFilters";
import { StyledHeaderTitle } from "../../App/styles/StylesCheckboxes";
import { Menus } from "../../App/updates/Menus";
import { ShowLg, ShowMd } from "../../App/updates/ShowBoxes";
import { SpaceAppBar } from "../../AppBar/AppBar";
import { NewsView } from "../News/NewsView";

import { TelescopesMainGrid } from "./TelescopesMainGrid";

export function FilterPagesLayout({ isNews }) {
  return (
    <>
      <SpaceAppBar />
      <Grid
        container
        columns={12}
        direction="row"
        flexDirection="row-reverse"
      >
        <Grid item xs={12} sm={12} md={12} lg={3} >
          {isNews ? (
            <>
              <ShowMd >
                <Box sx={{ ml: 8, mt: 2, bgcolor:"#f8f8f9"}}>
                  <Menus label={"Filter News"}>
                    <Box p={2}>
                      <NewsCheckboxes />
                    </Box>
                  </Menus>
                </Box>
              </ShowMd>
              <ShowLg>
                <Box sx={{ m: 2, bgcolor:"#f8f8f9" }}>
                  <StyledBorderBox sx={{bgcolor: "#f8f8f9"}}>
                    <StyledHeaderTitle variant="h6">
                      Browse Mission News
                    </StyledHeaderTitle>

                    <Box display="grid" py={1}>
                      <Divider flexItem variant="medium" alignSelf="center" />
                    </Box>
                    <FormControl>
                      <FormLabel>Missons:</FormLabel>
                      <Box p={2}>
                        <NewsCheckboxes />
                      </Box>
                    </FormControl>
                  </StyledBorderBox>
                </Box>
              </ShowLg>
            </>
          ) : (
            <StyledBorderBox sx={{bgcolor:"#f8f8f9"}}>
              <XSmallFilter />
              <SmallFilter />

              <MdFilter />
              <LgFilter />
            </StyledBorderBox>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={9}>
          {isNews ? <NewsView gridView /> : <TelescopesMainGrid />}
        </Grid>
        <ScrollTop />
      </Grid>
    </>
  );
}
