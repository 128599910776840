import { Box, Grid } from "@mui/material";
import {
  StyledAllNewsLink,
  StyledNewsSideBar,
} from "../../App/styles/StyledTelescopeGridCard";
import { StyledHeaderTitle } from "../../App/styles/StylesCheckboxes";
import { NewsView } from "./NewsView";

export const NewsSideBar = () => {
  return (
    <StyledNewsSideBar>
      <StyledHeaderTitle pl={2} variant="h6">
        Recent News
      </StyledHeaderTitle>

      <Box justifyContent="center">
        <Grid item>
          <NewsView maxNumberOfViews={3} />
        </Grid>
      </Box>

      <StyledAllNewsLink variant="h6" href={"/news"}>
        View All News
      </StyledAllNewsLink>
    </StyledNewsSideBar>
  );
};
