export const artemis = {
  id: "artemis",
  title: "Artemis",
  launchDate: " 2023",
  endDate: "",
  src: "/images/artemis.png",
  credit: "",
  button: "Artemis",
  missionStatus: "planned",
  missionType: "orbital",
  launchVehicle: "",
  launchSite: "",
  keyFact: ["Return humans to the Moon"],
  desc: {
    descId: "",
    headline: "The Artemis mission is expected to return humans to the Moon",
    mainDesc:
      "Artemis missions goal is to return humans to land and live on the moon",
    subDesc: "",
  },

  images: [
    {
      imageId: "artemisA",
      image: "/imageList/artemis/gateway.png",
      imageCredit: "",
      imageDesc:
        "Gateway will remain in orbit for more than a decade supporting long-term science and human exploration on and around the Moon",
    },

    {
      imageId: "artemisB",
      image: "/imageList/artemis/orion.png",
      imageCredit: "NASA",
      imageDesc:
        "NASA spacecraft that will carry astronauts from Earth to lunar orbit and back.",
    },

    {
      imageId: "artemisC",
      image: "/imageList/artemis/humanLand.png",
      imageCredit: "NASA",
      imageDesc:
        "Will take astronauts from lunar orbit to the surface and back to orbit.",
    },

    {
      imageId: "",
      image: "",
      imageCredit: "",
      imageDesc: "",
    },
  ],
  recentNews: [
    {
      newsId: "artemisNew",
      image: "/imageList/artemis/artemismoon.png",
      imageCredit: "NASA/Ben Smegelsky",
      title: "Artemis moon rocket test",
      month: "7",
      year: "2022",
      summary:
        "the wet dress rehearsal, simulated every stage of launch without the rocket leaving the launchpad at Kennedy Space Center in Florida. It included loading all four rocket tanks with supercold propellant, going through a full countdown and draining the rocket tanks. Possible launch windows for sending Artemis I on its journey to the moon starting in late summer: August 23 to August 29, September 2 to September 6 and beyond.",
      link: "https://www.cnn.com/2022/06/21/world/nasa-artemis-prelaunch-test-results-scn/index.html",
    },
  ],
};
