export const juno = {
  id: "juno",
  title: "Juno",
  launchDate: "August 5, 2011",
  endDate: "active",
  isFavorite: true,
  src: "/images/juno.png",
  credit: "credit image",
  button: "Juno",
  missionStatus: "active",
  missionType: "orbital",
  launchVehicle: "Atlas V 551",
  launchSite: "Cape Canaveral Air Force Station, Fla.",
  keyFact: [
    "First close flyby of Jupiter’s moon Ganymede in more than 20 years",
  ],
  desc: {
    descId: "junoInfo",
    headline:
      "Spans the width of a basketball court and makes long, looping orbits around giant planet Jupiter. Had the first close flyby of Jupiter’s moon Ganymede in more than 20 years",
    mainDesc:
      "Juno mission is the second spacecraft in NASA's New Frontiers Program following New Horizons. Juno's mission has been to gain an understanding of the origin and evolution of Jupiter by investigating the existence of a solid planetary core, map the magnetic field, measure the amount of water and ammonia in the deep atmosphere, and observe Jupiter’s auroras. ",
    subDesc:
      "This primary mission was completed in July 2021. Now in it’s extended mission Juno continues its investigation of the solar system’s largest planet, its rings and moons",
  },

  images: [
    {
      imageId: "junoA",
      image: "/imageList/juno/jupiterBowling.png",
      imageCredit: "NASA/JPL-Caltech/SwRI/MSSS/Kevin M. Gill",
      imageDesc:
        " Each white spot is a raging storm as large as Earth. Resembles a bowling ball",
    },
    {
      imageId: "junoB",
      image: "/imageList/juno/southJupiter.png",
      imageCredit: "NASA/JPL-Caltech/SwRI/MSSS/Kevin M. Gill",
      imageDesc: "Jupiter's southern hemisphere",
    },
    {
      imageId: "junoC",
      image: "/imageList/juno/swirl.png",
      imageCredit:
        "NASA/JPL-Caltech/SwRI/MSSS enhanced by Gerald Eichstädt and Sean Doran (CC BY-NC-SA)",
      imageDesc:
        "Three of the white oval storms known as the String of Pearls and bands of Jupiter's light and dark clouds",
    },
    {
      imageId: "junoD",
      image: "/imageList/juno/winds.png",
      imageCredit: "NASA/JPL-Caltech/SwRI/MSSS",
      imageDesc:
        "Jupiter's strong winds create the many swirling storms visible near the top of its atmosphere",
    },
    {
      imageId: "junoE",
      image: "/imageList/juno/storms.png",
      imageCredit: "NASA/JPL-Caltech/SwRI/MSSS",
      imageDesc: " Two storms merging",
    },
    {
      imageId: "junoF",
      image: "/imageList/juno/topStorm.png",
      imageCredit:
        "NASA/JPL-Caltech/SwRI/MSSS enhanced by Gerald Eichstädt and Sean Doran (CC BY-NC-SA)",
      imageDesc: "A cyclonic storm in Jupiter’s northern hemisphere",
    },
    {
      imageId: "junoG",
      image: "/imageList/juno/ring.png",
      imageCredit: "NASA/JPL-Caltech/SwRI",
      imageDesc:
        "First image of Jupiter’s ring taken from the inside looking out",
    },
  ],
  recentNews: [
    {
      newsId: "",
      image: "",
      title: "",
      summary: "",
    },
  ],
};
