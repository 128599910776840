export const fermi = {
  id: "fermi",
  title: "Fermi Gamma Ray",
  launchDate: "June 11, 2008",
  endDate: "GBM 2018, LAT still active",
  src: "/images/fermigamma.png",
  credit: "credit image",
  button: "Fermi",
  missionStatus: "active",
  missionType: "observatory",
  launchVehicle: "Delta II 7920-H ",
  launchSite: "	Cape Canaveral, Fl",
  keyFact: [
    "Detected the most powerful gamma-ray blast astronomers have ever seen: a mysterious source glowing with more energy than 9,000 supernovas",
    "Discovered the first gamma-ray pulsar and gamma-ray 'bubbles' around the Milky Way",
  ],
  desc: {
    descId: "fermiInfo",
    headline:
      "Observes gamma-ray radiation, the most energetic form of radiation billions of times more energetic than the type of light visible to our eyes",
    mainDesc:
      "NASA's Fermi Gamma-ray Space Telescope is a powerful space observatory that unlocks a view in the high energy fields of the universe. The satellite sees pulsars, active galaxies powered by supermassive black holes, and the remnants of exploding stars. Fermi detects gamma rays using its two instruments, the Large Area Telescope (LAT) and the Gamma-ray Burst Monitor (GBM).  The GBM detects lower-energy emissions, which has helped it identify more than 2,000 gamma-ray bursts: energetic explosions in galaxies extremely far away. The LAT sees about one-fifth of the sky at a time and records gamma rays that are millions of times more energetic than visible light.",
    subDesc:
      "Fermi continues to map the entire high-energy gamma-ray sky every three hours. Physicists are able to study subatomic particles at energies far greater than those seen in ground-based particle accelerators. And cosmologists are gaining valuable information about the birth and early evolution of the Universe. ",
  },

  images: [
    {
      imageId: "fermiA",
      image: "/imageList/fermi/Fermi_YoungStellarGroupinginCygnusX.png",
      imageCredit: "NASA/IPAC/MSX",
      imageDesc: "Young stellar groupings in Cygnus X  ",
    },
    {
      imageId: "fermiB",
      image:
        "/imageList/fermi/File_NASA_s_Fermi_Detects_the_Highest-Energy_Light_from_a_Solar_Flare_ogv_-_Wikipedia.png",
      imageCredit: "NASA/Goddard Space Flight Center",
      imageDesc:
        "Fermi's observation of the highest-energy light ever associated with an eruption on the sun as of June 2012",
    },
    {
      imageId: "fermiC",
      image:
        "    /imageList/fermi/GMS__NASA_s_Fermi__Swift_See__Shockingly_Bright__Gamma-ray_Burst.png",
      imageCredit: " NASA/DOE/Fermi LAT Collaboration",
      imageDesc: "Blast of gamma rays from a dying star in a distant galaxy",
    },
    {
      imageId: "fermiD",
      image:
        "    /imageList/fermi/NASA_satellite_spots__Cannonball__pulsar_speeding_across_the_Milky_Way_-_The_Week.png",
      imageCredit: "NASA",
      imageDesc:
        "Located about 6,500 light-years away in the constellation Cassiopeia, J0002 spins 8.7 times a second, producing a pulse of gamma rays with each rotation",
    },
    {
      imageId: "fermiE",
      image:
        "    /imageList/fermi/NASA’s_Fermi_space_telescope_reveals_new_source_of_gamma_rays___Astronomy_com.png",
      imageCredit: "NASA’s Goddard Space Flight Center/S. Wiessinger",
      imageDesc:
        "The white dwarf star in V407 Cygni, shown here in an artist’s concept, went nova in 2010",
    },
  ],
  recentNews: [
    {
      newsId: "fermiNew",
      image:
        "/imageList/fermi/DESY_News__Front-row_view_reveals_exceptional_cosmic_explosion_-_Deutsches_Elektronen-Synchrotron_DESY.png",
      imageCredit: "DESY, Science Communication Lab",
      month: "6",
      year: "2021",
      title: "Bright Burst",
      // "Most energetic radiation and longest gamma-ray afterglow of gamma-ray burst to date",
      summary:
        "On 29 August 2019 the satellites Fermi and Swift detected a gamma-ray burst in the constellation of Eridanus. The event, catalogued as GRB 190829A according to its date of occurrence, turned out to be one of the nearest gamma-ray bursts observed so far, with a distance of about one billion lightyears. For comparison: The typical gamma-ray burst is about 20 billion lightyears away. “We were really sitting in the front row when this gamma-ray burst happened,” explains co-author Andrew Taylor from DESY",
      link: "https://www.desy.de/news/news_search/index_eng.html?openDirectAnchor=2080&two_columns=1",
    },
  ],
};
