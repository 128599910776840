import { Box, Card, CardMedia, IconButton, Link, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

//telescope grid card
export const StyledTelescopeGridCard = styled(Card)({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  borderRadius: 0,
});

export const StyledTelescopeCardMedia = styled(CardMedia)({
  height: "14em",
  paddingBottom: 3,
});

export const StyledCardStatus = styled(Typography)({
  alignItems: "baseline",
  letterSpacing: 1.5,
  height: "100%",
  fontWeight: 700,
  flexGrow: 1,
  textAlign: "flex-start",
});

export const StyledCardLaunchDate = styled(Typography)({
  alignItems: "baseline",
  letterSpacing: 1,
  flexGrow: 2,
});

//telescope list card
export const StyledTelescopeListCard = styled(Card)({
  display: "flex",
  flexDirection: "row",
  flexGrow: 1,
  bgcolor: "#e8e8e9",
  flexWrap: "wrap",
});

export const StyledTelescopeListCardMedia = styled(CardMedia)({
  minWidth: 350,
  maxWidth: 350,
  height: 280,
  padding: 3,
});

export const StyledListCardLiftoff = styled(Typography)({
  fontWeight: 800,
  letterSpacing: 0.5,
  lineHeight: 2,
  alignSelf: "center",
  alignItems: "baseline",
  flexGrow: 1,
  paddingRight: 15,
});

export const StyledListCardLaunchDate = styled(Typography)({
  alignItems: "baseline",
  letterSpacing: 0.5,
  flexGrow: 2,
  paddingBottom: 3,
  paddingRight: 12,
});

export const StyledListCardHeadline = styled(Typography)({
  textOverflow: "ellipsis",
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  maxWidth: 520,
});

export const StyledListActionArea = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
});
//news
export const StyledNewsSummary = styled(Typography)({
  height: "5em",
  maxWidth: "100%",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 3,
  overflow: "hidden",
  textOverflow: "ellipsis",
});

export const StyledNewsMedia = styled(CardMedia)({
  objectFit: "fill",
  width: "50em",
  height: "auto",
  alignSelf: "center",
});

export const StyledNewsCloseButtonBox = styled(Box)({
  top: 0,
  right: 0,
  position: "absolute",
  backgroundColor: "#fbfbfb70",
  borderBottomLeftRadius: "5px",
});

export const StyledNewsSideBar = styled(Paper)({
  borderRadius: "5px",
  paddingBottom: 5,
  paddingTop: 20,
  paddingLeft: 2,
  paddingRight: 2,
});

export const StyledAllNewsLink = styled(Link)({
  display: "flex",
  justifyContent: "center",
  paddingBottom: 2,
  paddingTop: 4,
  fontSize: "large",
});

//homepage

export const StyledHomeTelescopeCard = styled(Card)({
  minHeight: "100%",
  maxWidth: "24em",
  justifyContent: "space-evenly",
  alignItems: "stretch",
  variant: "outlined",
});

export const StyledHomeMedia = styled(CardMedia)({
  height: "15em",
  width: "100%",
});

export const StyledHomeCardFact = styled(Typography)({
  overflow: "hidden",
  whiteSpace: "wrap",
  textOverflow: "ellipsis",
  height: "8em",
});

//appbar

export const StyledLogoStart = styled(Typography)({
  fontSize: "2.5em",
  color: "#fbfbfb",
  fontWeight: 900,
  fontFamily: "Wire One",
  letterSpacing: 1,
  textAlign: "center",
});

export const StyledLogoEnd = styled(Typography)({
  fontSize: "2.5em",
  color: "#cdcdcd",
  fontWeight: 500,
  fontFamily: "Wire One",
  letterSpacing: 1,
  textAlign: "center",
});

export const StyledToggle = styled(IconButton)({
    borderRadius: "5px",
    border: "2px solid",
})