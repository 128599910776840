import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import { useSetRecoilState } from "recoil";
import {
  StyledNewsSummary,
  StyledTelescopeCardMedia,
} from "../../App/styles/StyledTelescopeGridCard";
import { getActiveNewsInfo } from "../../data/telescope";
import { ActiveNewsDialog } from "./NewsDialog";

export function NewsCard({ newsData }) {
  const setActiveNewsInfo = useSetRecoilState(getActiveNewsInfo);

  return (
    <Card
      display="flex"
      sx={{
        height: "100%",
      }}
      elevation={0}
    >
      <StyledTelescopeCardMedia
        component="img"
        image={newsData.image}
        alt={newsData.imageCredit}
        onClick={() => setActiveNewsInfo(newsData)}
      />

      <Typography variant="caption" sx={{ lineHeight: 1.2, pl: 1 }}>
        {newsData.imageCredit}
      </Typography>

      <CardContent
        sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
      >
        <Typography variant="h6" sx={{ lineHeight: 1.2, flexWrap: "wrap" }}>
          {newsData.title}
        </Typography>
        <Divider sx={{ pt: 1, mb: 2 }} />

        <StyledNewsSummary variant="subtitle2">
          {newsData.summary}
        </StyledNewsSummary>
      </CardContent>
      <CardActions>
        <ActiveNewsDialog onClick={() => setActiveNewsInfo(newsData)} />
      </CardActions>
    </Card>
  );
}
