export const kepler = {
  id: "kepler",
  title: "Kepler Telescope",
  launchDate: "March 7, 2009",
  endDate: "November 15, 2018",
  src: "/images/kepler.png",
  credit: "credit image",
  button: "Kepler",
  missionStatus: "Past",
  missionType: "orbital",
  launchVehicle: "Delta 7925-10L",
  launchSite: "Cape Canaveral, Fla. ",
  keyFact: [
    "Found the first planet, Kepler-22b, in the habitable zone of a star outside our solar system",
    "Revealed our night sky to be filled with more planets even than stars",
    "Provided a new way to assess whether a planet has a solid surface, like Earth, or is made mostly of gas, like Neptune. This distinction helps scientists zero in on potential Earth-like planets and better the odds for finding life in the cosmos",
  ],

  desc: {
    descId: "keplerInfo",
    headline:
      "Designed to monitor about 100,000 main-sequence stars over a period of three-and-a-half years but continued operations for nine years",
    mainDesc:
      "The Kepler Mission was designed to survey our region of the Milky Way galaxy looking for Earth-size and smaller rocky potentially habitable planets.  For four years, the spacecraft stared continuously at about 150,000 stars, looking for tiny dips in their brightness caused by the passage of planets across their faces. Kepler found 2,335 confirmed planets and another 1,699 candidates, bringing its tally to 4,034. That number includes about 50 worlds that may be about the same size and temperature as Earth.",
    subDesc:
      " In May 2013, the second of Kepler's four orientation-maintaining reaction wheels failed, and the spacecraft lost its superprecise pointing ability, bringing the original mission to a close. But mission managers figured out a way to stabilize Kepler using sunlight pressure, and the spacecraft soon embarked on its K2 mission, hunting exoplanets on a more limited basis, observing comets and asteroids in our own solar system, as well as supernovas and a range of other objects and phenomena.",
  },

  images: [
    {
      imageId: "keplerA",
      image:
        "/imageList/kepler/Cursor_and__Cotton_Candy__Planet_Mysteries_Unravel_in_New_Hubble_Observations___NASA.png",
      imageCredit:
        "NASA, ESA, and L. Hustak, J. Olmsted, D. Player and F. Summers (STScI)",
      imageDesc:
        "Kepler 51 system discovered by Kepler space telescope in 2012",
    },
    {
      imageId: "keplerB",
      image:
        "/imageList/kepler/Cursor_and_NASA_s_Kepler_space_telescope_discovers_FIVE_double-sun_planets_that__could_support_alien_life_ ___Daily_Mail_Online.png",
      imageCredit: "NASA Ames/JPL-Caltech/T. Pyle",
      imageDesc:
        "Planet Kepler-452b, the first near-Earth-size world to be found in the habitable zone of star that is similar to our sun",
    },
    {
      imageId: "keplerC",
      image:
        "/imageList/kepler/Kepler_Space_Telescope_Discovers_95_More_Alien_Planets___Space.png",
      imageCredit: " M. Kornmesser/ESA/Hubble/ESO",
      imageDesc:
        "K2 discovers 95 more alien planets on Feb. 15, 2018, bringing the total to 292",
    },
    {
      imageId: "keplerD",
      image:
        "/imageList/kepler/Nasa_s_Kepler_telescope_finds_10_Earth-like_planets___We_are_not_alone____Space___The_Guardian.png",
      imageCredit: "AP",
      imageDesc:
        "Artist rendering of some of the 219 new planet candidates, 10 of which are near-Earth size and in the habitable zone of their star in the constellation Cygnus",
    },
    {
      imageId: "keplerE",
      image: "/imageList/kepler/earth.png",
      imageCredit: "NASA/Ames Research Center",
      imageDesc: "Image of Earth taken at 94 million miles away",
    },
    {
      imageId: "keplerF",
      image: "/imageList/kepler/fullView.png",
      imageCredit: "NASA/Ames/JPL-Caltech",
      imageDesc:
        "Kepler full field of view — an expansive star-rich patch of sky in the constellations Cygnus and Lyra",
    },
    {
      imageId: "keplerG",
      image: "/imageList/kepler/milkyWay.png",
      imageCredit: "Carter Roberts",
      imageDesc: "View of the Milky Way",
    },
    {
      imageId: "keplerH",
      image: "/imageList/kepler/fullView2.png",
      imageCredit: "NASA/Ames Research Center",
      imageDesc:
        "The “last light” image taken on September 25, 2018 pointed at the constillation Aquarius",
    },
    {
      imageId: "keplerL",
      image: "/imageList/kepler/comet.png",
      imageCredit: "NASA Ames/W Stenzel; SETI Institute/D Caldwell",
      imageDesc:
        "Oort Cloud native Comet Siding Spring as it passed through K2's Campaign 2 field-of-view on its long journey around the sun",
    },
    {
      imageId: "keplerI",
      image: "/imageList/kepler/kepler444.png",
      imageCredit: "Tiago Campante/Peter Devine",
      imageDesc:
        "System Kepler-444 formed when the Milky Way galaxy was a youthful two billion years old.",
    },
    {
      imageId: "keplerJ",
      image: "/imageList/kepler/mosaicClusters.png",
      imageCredit: "NASA Ames/T Barclay/W Stenzel",
      imageDesc:
        "Mosaic of two open clusters Messier 35 (top) and NGC 2158 (bottom)",
    },
    {
      imageId: "keplerK",
      image: "/imageList/kepler/kepler186f.png",
      imageCredit: "NASA Ames/JPL-Caltech/T. Pyle",
      imageDesc:
        "Kepler-186f , the first validated Earth-size planet to orbit a distant star in the habitable zone",
    },
  ],
  recentNews: [
    {
      newsId: "tatooine",
      image: "/imageList/kepler/tatoo.png",
      imageCredit: "NASA/JPL-Caltech",
      title: "Earth-Sized Habitable Planets",
      month: "4",
      year: "2017",
      summary:
        "Thanks to observatories such as NASA's Kepler space telescope, we know that two-star systems can indeed support planets, although planets discovered so far around double-star systems are large and gaseous. Scientists wondered: If an Earth-size planet were orbiting two suns, could it support life?",
      link: "https://www.nasa.gov/feature/jpl/earth-sized-tatooine-planets-could-be-habitable",
    },
  ],
};
