import { Grid, Box } from "@mui/material";


export const TelescopeMediaGrid = ({ cardMedia, children }) => {
    return (
  
      <Grid
        container
        // spacing={1}
        display="flex"
        justifyContent="center"
        alignSelf="center"
        direction="row"
        gap={10}
        pt={3}
      >
        <Box display="flex" alignSelf="center" justifyContent="flex-end">
          {cardMedia}
        </Box>
  
        {children}
        
      </Grid>
    );
  };