import { Box, Grid, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { SectionDivider } from "../../App/Divider";
import { SpaceAppBar } from "../../AppBar/AppBar";
import {
  ACTIVE,
  getAllNewsDataForTelescope,
  getTelescopeInfo,
} from "../../data/telescope";
import { NewsView } from "../News/NewsView";
import { FactTable } from "./FactsTable";

import { StatusBox } from "../../App/StatusBox";
import {
  StyledEndDate,
  StyledTelescopeCardMedia,
} from "../../App/styles/StylesTelescopePage";
import { ButtonSlider } from "../../App/telescopeImgs/ButtonSlider";
import { ImageContainer } from "../../App/telescopeImgs/ImageContainer";
import { statusLabelFlex, textBox } from "../../Styles/styles";
import { TelescopeInfoGrid } from "./TelescopeInfoGrid";
import { TelescopeMediaGrid } from "./TelescopeMediaGrid";

export function Telescope() {
  const { telescopeId } = useParams();
  const telescope = useRecoilValue(getTelescopeInfo(telescopeId));
  const newsData = useRecoilValue(getAllNewsDataForTelescope(telescopeId));

  const telescopeImages = telescope.images || [];
  const isActive = telescope.missionStatus?.toLowerCase() === ACTIVE;

  return (
    <Grid container>
      <Grid item xs={12}>
        <SpaceAppBar />
      </Grid>

      <TelescopeMediaGrid
        cardMedia={
          <StyledTelescopeCardMedia
            component="img"
            src={telescope.src}
            alt={telescope.title}
          />
        }
      >
        <TelescopeInfoGrid
          variant="h3"
          title={telescope.title}
          launch={telescope.launchDate}
        >
          <Box style={textBox} columnGap={5}>
            <StatusBox
              item={telescope}
              statusLabel={statusLabelFlex}
              variant="h6"
            />

            {!isActive && (
              <StyledEndDate variant="h6">{telescope.endDate}</StyledEndDate>
            )}
          </Box>
        </TelescopeInfoGrid>
      </TelescopeMediaGrid>

      <SectionDivider sm={9} />

      <Grid container spacing={2} justifyContent="space-evenly" pb={4}>
        <Box>
          <Stack sx={{ maxWidth: "45em", p: 3 }}>
            <Typography variant="h4" gutterBottom>
              Mission Info
            </Typography>

            <Typography variant="body1" maxWidth="33em">
              {telescope.desc.mainDesc}
            </Typography>

            <Typography variant="body1" maxWidth="33em" gutterBottom>
              {telescope.desc.subDesc}
            </Typography>
          </Stack>
        </Box>

        <FactTable />
      </Grid>

      {!!telescopeImages.length && <ImageContainer />}

      {!!newsData.length && (
        <>
          <SectionDivider sm={10} />

          <Grid container justifyContent="center" pb={4}>
            <Grid item sm={10}>
              <Typography variant="h4">Mission News</Typography>

              <Grid item sx={{ alignItems: "stretch" }}>
                <NewsView telescopeId={telescopeId} gridView />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      <br />

      <Grid item sm={12} sx={{ overflow: "scroll" }}>
        <ButtonSlider />
      </Grid>
    </Grid>
  );
}
