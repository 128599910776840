import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { AppBar, Toolbar } from "@mui/material";
import Fab from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

import FilterListIcon from "@mui/icons-material/FilterList";
import MergeTypeIcon from "@mui/icons-material/MergeType";
import SortIcon from "@mui/icons-material/Sort";
import { Menus } from "../App/updates/Menus";
import { SortSelect } from "./select/SortSelect";
import { MissionStatusFilter } from "./checkboxes/StatusCheckboxes";
import { MissionTypeCheckboxes } from "./checkboxes/TypesCheckboxes";

const useStyles = makeStyles({
  button: {
    position: "fixed",
    right: 50,
    bottom: 20,
  },
});

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: "0 auto",
});

export const ScrollTop = ({ isMobile }) => {
  const classes = useStyles();

  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const checkScrollTop = () => {
      if (window.pageYOffset > 640) {
        setShowScroll(true);
      } else if (window.pageYOffset <= 640) {
        setShowScroll(false);
      }
    };

    window.addEventListener("scroll", checkScrollTop);

    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, []);

  return isMobile ? (
    <AppBar
      position="fixed"
      color="primary"
      sx={{ top: "auto", bottom: 0, display: showScroll ? "unset" : "none" }}
    >
      <Toolbar>
        <Menus isIcon />

        <StyledFab>
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <KeyboardArrowUpIcon size="large" />
          </Fab>
        </StyledFab>
        <Box sx={{ flexGrow: 1 }} />

        <Menus label={<SortIcon />}>
          <SortSelect />
        </Menus>
        <Menus label={<FilterListIcon />}>
          <MissionStatusFilter />
        </Menus>
        <Menus label={<MergeTypeIcon />}>
          <MissionTypeCheckboxes />
        </Menus>
      </Toolbar>
    </AppBar>
  ) : (
    <Box
      style={{ display: showScroll ? "unset" : "none" }}
      className={classes.button}
    >
      <Fab
        color="primary"
        aria-label="add"
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      >
        <KeyboardArrowUpIcon size="large" />
      </Fab>
    </Box>
  );
};
