import { Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledHeaderTitle = styled(Typography)({
  display: "flex",
  color:"#103964",
  fontSize: "larger",
});

export const StyledWrapperPaper = styled(Paper)({
    marginBottom: 5,
    maxHeight: "fit-content",
    maxWidth: "24em",
    minWidth: "19em",
    paddingBottom: 4,
})