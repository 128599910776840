import { Box, Container, Typography } from "@mui/material";
import { ButtonSlider } from "../../App/telescopeImgs/ButtonSlider";
import { TopTelescopesOther } from "./HomepageCard";

export const HomePageBody = () => {
  return (
    <>
      <Container>
        <Typography
          variant="h6"
          sx={{
            fontSize: "larger",
            display: "flex",
            justifyContent: "center",
            paddingBlock: 10,
          }}
        >
          Collection of space missions since we first voyaged into the cosmos
          starting in 1958
        </Typography>

        <Box display="flex" justifyContent="center">
          <Typography sx={{ pt: 5 }} variant="h5" fontWeight={700}>
            Popular Telescopes
          </Typography>
        </Box>

        <TopTelescopesOther />
      </Container>

      <Box display="flex" justifyContent="center">
        <Typography sx={{ pt: 4, pb: 2 }} variant="h5" fontWeight={700}>
          All Telescopes
        </Typography>
      </Box>

      <ButtonSlider />
    </>
  );
};
