import { Box, Typography } from "@mui/material";
import { ACTIVE, FUTURE } from "../data/telescope";

function getLabel(status) {
  switch (status?.toLowerCase()) {
    case ACTIVE:
      return "Still Exploring";
    case FUTURE:
      return "Future Explorer";
    default:
      return "Mission Ended";
  }
}

export function StatusBox({ item, statusLabel, variant }) {
  return (
    <Box display="flex">
      <Typography style={statusLabel} variant={variant}>
        {getLabel(item.missionStatus)}
      </Typography>
    </Box>
  );
}
