import { Box, Grid, Stack, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { ChipsArray } from "../../App/headerActions/Chip";
import ToggleButtons from "../../App/headerActions/ToggleButton";
import { ShowMd } from "../../App/updates/ShowBoxes";
import { gridViewMode } from "../../data/app";
import { getAllFilteredTelescopesInfo } from "../../data/telescope";
import { NewsView } from "../News/NewsView";
import { GridViewCards } from "../Telescopes/GridView";
import { ListView } from "../Telescopes/ListView";

export function TelescopesMainGrid() {
  const viewMode = useRecoilValue(gridViewMode);
  const telescopes = useRecoilValue(getAllFilteredTelescopesInfo);

  return (
    <>
      <Stack direction="row" alignItems="flex-end">
        <Box sx={{ flexGrow: 2, justifyContent: "space-evenly" }}>
          <ChipsArray />
        </Box>
        <Box alignSelf="flex-end">
          <ToggleButtons />
        </Box>
      </Stack>

      {!telescopes.length ? (
        <>
          <Box
            display="flex"
            justifyContent="center"
            fontWeight={600}
            fontSize="larger"
          >
            <Typography variant="h6x" alignSelf="center">
              No Rows match filter
            </Typography>
          </Box>
        </>
      ) : viewMode ? (
        <Grid container columns={12}>
          <GridViewCards />
        </Grid>
      ) : (
        <ListView />
      )}

      <ShowMd>
        <Grid container>
          <NewsView gridView />
        </Grid>
      </ShowMd>
    </>
  );
}
