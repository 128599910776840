import { MenuItem, OutlinedInput, Select } from "@mui/material";
import { useRecoilState } from "recoil";
import { ALL_SORT_VALUES, sortedTelescopesState } from "../../data/telescope";

export function SortSelect() {
  const [sortType, setSortType] = useRecoilState(sortedTelescopesState);

  
  return (
    <Select
      variant="outlined"
      onChange={(e, b) => {
        setSortType(e.target.value);
      }}
      value={sortType}
      input={
        <OutlinedInput
          sx={{
            borderRadius: "5px",
            mr: 1,
            mt: 3
            
          }}
          fullWidth
        />
      }
    >
      {ALL_SORT_VALUES.map((item, index) => (
        <MenuItem key={index} value={item}>
          {item[2]}
        </MenuItem>
      ))}
    </Select>
  );
}
