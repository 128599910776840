import { Card, CardMedia, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

//telescope grid card
export const StyledFactsCard = styled(Card)({
  maxHeight: "fit-content",
  maxWidth: "25em",
});

export const StyledTelescopeCardMedia = styled(CardMedia)({
  maxWidth: 450,
  minWidth: 300,
  height: 315,
  minHeight: 300,
  objectFit: "cover",
  alignSelf: "center",
  padding: 1,
  margin: 1,
});

export const StyledEndDate = styled(Typography)({
  flexGrow: 1,
  alignItems: "baseline",
  display: "flex",
  paddingBottom: 1,
  letterSpacing: 0,
});
