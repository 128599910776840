export const spirit = {
    id: "spirit",
    title: "Spirit",
    launchDate: "June 10, 2003",
    endDate: "March 22, 2010",
    src: "/images/spirit.png",
    credit: "",
    button: "Spirit",
    missionStatus: "Past",
    missionType: "rover",
    launchVehicle: "Delta II 7925",
    launchSite: "Cape Canaveral, Fl",
    keyFact: ["Spirit was able to use it's rock abrasion tool to provide the first-ever look inside a rock on Mars at 'Adirondack'"],
    desc: {
      descId: "spiritInfo",
      headline: "Main among it's goals was to search for and characterize a wide range of rocks and soils for clues to past water activity on Mars",
      mainDesc: "Spirit and Opportunity were solar-powered rovers landed on opposite sides of Mars conducting field geology and making atmospheric observations. Spirit landed on Jan. 4, 2004 on a plain inside Gusev Crater, a possible former lake in a giant impact crater",
      subDesc: "",
    },

    images: [
      {
        imageId: "spiritA",
        image: "/imageList/spirit/spiritA.png",
        imageCredit: "",
        imageDesc: "",
      },
    
      {
        imageId: "spiritB",
        image: "/imageList/spirit/spiritB.png",
        imageCredit:
          "",
        imageDesc:
          "",
      },

      {
        imageId: "spiritC",
        image: "/imageList/spirit/spiritC.png",
        imageCredit: "",
        imageDesc: "",
      },
    
      {
        imageId: "spiritD",
        image: "/imageList/spirit/spiritD.png",
        imageCredit: "",
        imageDesc: "",
      },
    // ],
    // recentNews: [
    //   {
    //     newsId: "",
    //     image: "",
    //     title: "",
    //     summary: "",
    //   },
    ],
  };