import { Box, Stack, Typography } from "@mui/material";
import {
  StyledListCardLaunchDate,
  StyledListCardLiftoff,
} from "../../App/styles/StyledTelescopeGridCard";
import { textBox } from "../../Styles/styles";

export const TelescopeInfoGrid = ({
  variant,
  title,
  launch,
  children,
  headline,
  button,
}) => {
  return (
    <Box display="flex" alignSelf="center">
      <Stack direction="column" p={1}>
        <Typography variant={variant}  pt={3}>
          {title}
        </Typography>

        <Box style={textBox}>
          <StyledListCardLiftoff variant="h6">Liftoff</StyledListCardLiftoff>
          <StyledListCardLaunchDate variant="h6">
            {launch}
          </StyledListCardLaunchDate>
        </Box>

        <Box style={textBox}>{children}</Box>

        {headline}
        {button}
      </Stack>
    </Box>
  );
};
