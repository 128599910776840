import { atom, selector, selectorFamily } from "recoil";
import { app } from "./app";

export const ACTIVE = "active";
export const INACTIVE = "past";
export const FUTURE = "planned";

export const FLYBY = "flyby";
export const ORBITAL = "orbital";
export const OBSERVE = "observatory";
export const LANDER = "lander";
export const ROVER = "rover";
export const OTHER = "other";

//0,1,2
export const ALL_STATES = [ACTIVE, INACTIVE, FUTURE];
//0,1,2,3
export const ALL_TYPES = [FLYBY, ORBITAL, OBSERVE, ROVER];

export const ALL_SORT_VALUES = [
  ["launchDate", true, "Launch Date Newest to Oldest"],
  ["launchDate", false, " Launch Date Oldest to Newest"],
  ["title", true, "Title A-Z"],
  ["title", false, "Title Z-A"],
];

export const excludedNewsIds = atom({
  key: "excludedNewsIds",
  default: [],
});

export const filteredTelescopesStates = atom({
  key: "filteredTelescopesStates",
  default: [],
});

export const filteredTelescopesTypes = atom({
  key: "filteredTelescopesTypes",
  default: [],
});

export const sortedTelescopesState = atom({
  key: "sortedTelescopesState",
  default: ALL_SORT_VALUES[0],
});

export const getAllNewsData = selector({
  key: "getAllNewsData",
  get: ({ get }) =>
    get(getAllTelescopesInfo)
      .map(({ id, title, recentNews = [] }) =>
        recentNews.map((item) => ({
          ...item,
          telescopeId: id,
          telescopeTitle: title,
        }))
      )
      .flat()
      .filter((item) => !!item.newsId),
});

export const getAllNewsDataFiltered = selector({
  key: "getAllNewsDataFiltered",
  get: ({ get }) =>
    get(getAllNewsData).filter(
      (item) => !get(excludedNewsIds).includes(item.telescopeId)
    ),
});

export const getAllNewsDataForTelescope = selectorFamily({
  key: "getAllNewsDataForTelescope",
  get:
    (telescopeId) =>
    ({ get }) =>
      !telescopeId
        ? get(getAllNewsDataFiltered)
        : get(getAllNewsData).filter(
            (item) => item.telescopeId === telescopeId
          ),
});

export const getActiveNewsInfo = atom({
  key: "getActiveNewsInfo",
  default: undefined,
});

export const getAllTelescopesInfo = selector({
  key: "getAllTelescopesInfo",
  get: ({ get }) =>
    get(app).telescopes.map((item) => ({
      ...item,
      launchDateTimestamp: new Date(item.launchDate),
      images: item.images
        ?.filter((image) => !!image.imageId)
        .map((image) => ({
          ...image,
          telescopeId: item.id,
          telescopeTitle: item.title,
        })),
    })),
});

export const getAllFilteredTelescopesInfo = selector({
  key: "getAllFilteredTelescopesInfo",
  get: ({ get }) =>
    get(getAllTelescopesInfo)
      .filter((item) => {
        const allStates = get(filteredTelescopesStates);
        const allTypes = get(filteredTelescopesTypes);

        if (allStates.length) {
          if (!allStates.includes(item.missionStatus.toLowerCase())) {
            return false;
          }
        }

        if (allTypes.length) {
          if (!allTypes.includes(item.missionType.toLowerCase())) {
            return false;
          }
        }

        return true;
      })
      .sort((a, b) => {
        const [field, sortAsc] = get(sortedTelescopesState);

        let aValue = a[field] || "";
        let bValue = b[field] || "";
        const multiplier = sortAsc ? 1 : -1;

        if (field.toLowerCase().includes("date")) {
          aValue = new Date(aValue);
          bValue = new Date(bValue);

          if (aValue.toString() === "Invalid Date") {
            aValue = Infinity;
          }

          if (bValue.toString() === "Invalid Date") {
            bValue = Infinity;
          }
        }

        if (typeof aValue === "string") {
          return aValue?.localeCompare(bValue) * multiplier;
        }

        return (aValue - bValue) * multiplier;
      }),
});

export const getTelescopeInfo = selectorFamily({
  key: "getTelescopeInfo",
  get:
    (telescopeId) =>
    ({ get }) => {
      return get(getAllTelescopesInfo).find((item) => item.id === telescopeId);
    },
});
