import { Link, Stack } from "@mui/material";

export const AppBarLinks = () => {
  return (
    <Stack direction="row" spacing={12} mr={7} fontSize="larger">
      <Link
        href="/telescopes"
        sx={{ color: "#fbfbfb", fontWeight: 700,  }}
      >
        Telescopes
      </Link>

      <Link
        href="/news"
        sx={{ color: "#fbfbfb", fontWeight: 700, }}
      >
        News
      </Link>

      <Link
        href="/resources"
        sx={{ color: "#fbfbfb", fontWeight: 700,  }}
      >
        Resources
      </Link>
    </Stack>
  );
};