export const voyager2 = {
  id: "voyager2",
  title: "Voyager 2",
  launchDate: "August 20, 1977",
  endDate: " active",
  src: "/images/voyager2.png",
  credit: "credit image",
  button: "Voyager 2",
  missionStatus: "active",
  missionType: "flyby",
  launchVehicle: "Titan IIIE-Centaur",
  launchSite: "Cape Canaveral, Fla",
  keyFact: [
    "Discovered a 14th moon at Jupiter",
    "At Uranus, discovered 10 new moons and two new rings",
    "At Neptune discovered five moons, four rings, and a Great Dark Spot.",
  ],
  desc: {
    descId: "voyager2Info",
    headline:
      "Only spacecraft to study all four of the solar system's giant planets at close range",
    mainDesc:
      "Voyager 2 is a space probe launched by NASA as part of the Voyager Program.  It’s primary mission  was to study Jupiter, Saturn, Uranus, and Neptune. That mission was completed in 1989 and since then the primary objective has been studying interstellar space. With over 45 years in space Voyager 2 is still in communication with Earth via the Deep Space Network.",
    subDesc:
      "In November 2018 the spacecraft crossed the heliopaus, the boundary of the heliosphere, and entered interstellar space. It is now heading south into the constellation Pavo.",
  },

  images: [
    {
      imageId: "voyager2A",
      image:
        "/imageList/voyager2/Cursor_and_Voyager_-_Images_Voyager_Took_of_Neptune (1).png",
      imageCredit: "NASA/JPL",
      imageDesc: "Neptune's moon Triton",
    },
    {
      imageId: "voyager2B",
      image:
        "/imageList/voyager2/Cursor_and_Voyager_-_Images_Voyager_Took_of_Neptune (2).png",
      imageCredit: "NASA/JPL",
      imageDesc: "Neptune's moon Triton",
    },

    {
      imageId: "voyager2D",
      image:
        "/imageList/voyager2/Cursor_and_Voyager_-_Images_Voyager_Took_of_Neptune (4).png",
      imageCredit: "NASA/JPL",
      imageDesc: "Neptune's south pole",
    },
    {
      imageId: "voyager2F",
      image:
        "/imageList/voyager2/Cursor_and_Voyager_-_Images_Voyager_Took_of_Neptune (6).png",
      imageCredit: "NASA/JPL",
      imageDesc: "Neptune and Triton",
    },
    {
      imageId: "voyager2C",
      image:
        "/imageList/voyager2/Cursor_and_Voyager_-_Images_Voyager_Took_of_Neptune (3).png",

      imageCredit: "NASA/JPL",
      imageDesc: "Neptune's moon Triton",
    },

    {
      imageId: "voyager2E",
      image:
        "/imageList/voyager2/Cursor_and_Voyager_-_Images_Voyager_Took_of_Neptune (5).png",
      imageCredit: "NASA/JPL",
      imageDesc: "Neptune's moon Triton",
    },

    {
      imageId: "voyager2G",
      image:
        "/imageList/voyager2/Cursor_and_Voyager_-_Images_Voyager_Took_of_Neptune.png",
      imageCredit: "NASA/JPL",
      imageDesc: "Great dark spot - Neptune",
    },
    {
      imageId: "voyager2H",
      image:
        "/imageList/voyager2/Cursor_and_Voyager_-_Images_Voyager_Took_of_Saturn.png",
      imageCredit: "NASA/JPL",
      imageDesc: "Montage of Saturnian system",
    },
    {
      imageId: "voyager2I",
      image:
        "/imageList/voyager2/Cursor_and_Voyager_-_Images_Voyager_Took_of_Uranus (1).png",
      imageCredit: "NASA/JPL",
      imageDesc: "Uranus' moon Titania",
    },
    {
      imageId: "voyager2J",
      image:
        "/imageList/voyager2/Cursor_and_Voyager_-_Images_Voyager_Took_of_Uranus (2).png",
      imageCredit: "NASA/JPL",
      imageDesc: "Uranus' moon Miranda",
    },
    {
      imageId: "voyager2K",
      image:
        "/imageList/voyager2/Cursor_and_Voyager_-_Images_Voyager_Took_of_Uranus (3).png",
      imageCredit: "NASA/JPL",
      imageDesc: "Uranus' moon Miranda",
    },
    {
      imageId: "voyager2L",
      image:
        "/imageList/voyager2/Cursor_and_Voyager_-_Images_Voyager_Took_of_Uranus (4).png",
      imageCredit: "NASA/JPL",
      imageDesc: "Mosaic of Uranus moon Miranda",
    },
    {
      imageId: "voyager2M",
      image:
        "/imageList/voyager2/Cursor_and_Voyager_-_Images_Voyager_Took_of_Uranus (5).png",
      imageCredit: "NASA/JPL",
      imageDesc: "Uranus cresent",
    },
    {
      imageId: "voyager2N",
      image:
        "/imageList/voyager2/Cursor_and_Voyager_-_Images_Voyager_Took_of_Uranus (6).png",
      imageCredit: "NASA/JPL",
      imageDesc: "Uranus moon Titania",
    },
    {
      imageId: "voyager2O",
      image:
        "/imageList/voyager2/Cursor_and_Voyager_-_Images_Voyager_Took_of_Uranus.png",
      imageCredit: "NASA/JPL",
      imageDesc: "Uranus moon Oberon",
    },
    {
      imageId: "voyager2W",
      image: "/imageList/voyager2/volcano.png",
      imageCredit: "NASA/JPL-Caltech/Kevin M. Gill",
      imageDesc: "Volcanic eruptions on Jupiters moon Io",
    },
    {
      imageId: "voyager2P",
      image:
        "/imageList/voyager2/Voyager_-_Images_Voyager_Took_of_Neptune (1).png",
      imageCredit: "NASA/JPL",
      imageDesc: "Great dark spot and white clouds of Neptune",
    },
    {
      imageId: "voyager2Q",
      image:
        "/imageList/voyager2/Voyager_-_Images_Voyager_Took_of_Neptune (2).png",
      imageCredit: "NASA/JPL",
      imageDesc: "Neptune and moon Triton",
    },
    {
      imageId: "voyager2R",
      image:
        "/imageList/voyager2/Voyager_-_Images_Voyager_Took_of_Neptune (3).png",
      imageCredit: "NASA/JPL",
      imageDesc: "Southern hemisphere of Triton",
    },

    {
      imageId: "voyager2S",
      image: "/imageList/voyager2/Voyager_-_Images_Voyager_Took_of_Neptune.png",
      imageCredit: "NASA/JPL",
      imageDesc: "Neptune",
    },
    {
      imageId: "voyager2T",
      image: "/imageList/voyager2/Voyager_-_Images_Voyager_Took_of_Saturn.png",
      imageCredit: "NASA/JPL",
      imageDesc: "View of Saturn",
    },
    {
      imageId: "voyager2U",
      image:
        "/imageList/voyager2/Voyager_-_Images_Voyager_Took_of_Uranus (1).png",
      imageCredit: "NASA/JPL",
      imageDesc: "Chevron on Uranus moon Miranda",
    },

    {
      imageId: "voyager2V",
      image: "/imageList/voyager2/Voyager_-_Images_Voyager_Took_of_Uranus.png",
      imageCredit: "NASA/JPL",
      imageDesc: "Mosaic of Uranus moon Ariel",
    },
  ],
  recentNews: [
    {
      newsId: "",
      image: "",
      title: "",
      summary: "",
    },
  ],
};
