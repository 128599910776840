export const swift = {
  id: "swift",
  title: "Swift",
  launchDate: "November 20, 2004",
  endDate: " active",
  src: "/images/swift.png",
  credit: "credit image",
  button: "Swift",
  missionStatus: "active",
  missionType: "observatory",
  launchVehicle: "Delta 7320",
  launchSite: "Kennedy Space Center, Cape Canaveral, Fla",
  keyFact: [
    "Discovered a pair of distant explosions that produced the highest-energy light yet seen from gamma-ray bursts, at least 10 times brighter than a typical supernova.",
  ],
  desc: {
    descId: "swiftInfo",
    headline:
      "Designed to solve the 35-year-old mystery of the origin of gamma-ray bursts",

    mainDesc:
      "NASA’s Swift missions objectives are to determine the origin of Gamma Ray Bursts (GRB) the universe’s most powerful explosions.  Gamma-ray bursts are fleeting events, lasting only a few milliseconds to a few minutes, never to appear in the same spot again. They occur from our vantage point about once a day. The afterglow phenomenon follows the initial gamma-ray flash in most bursts. Within 20 to 75 seconds of a detected GRB,  Swift alerts scientists to it’s location so they are able to follow up using other ground- and space-based telescopes.   Swift will then rotate autonomously so the onboard X-ray and optical telescopes can view the burst. The afterglows are monitored over the hours, days and even weeks through their durations . Swift’s ability to rapidly repoint and its sensitive X-ray and ultraviolet imaging have led it to observe a wide variety of sources. The spacecraft now receives an average of five “target-of-opportunity” requests from the community each day to respond to objects ranging from comets to distant black holes ",
    subDesc: "",
  },

  // images: [
  //   {
  //     imageId: "hippaA",
  //     image:
  //       "/imageList/heaoB/Einstein_Observatory___Center_for_Astrophysics.png",
  //        imageCredit: "",
  //   imageDesc:"",

  //   },
  //   {
  //     imageId: "hippaB",
  //     image: "/imageList/heaoB/heaoBCarinae.png",
  //    imageCredit: "",
  //   imageDesc:"",

  //   },
  //   {
  //     imageId: "hippaC",
  //     image: "/imageList/heaoB/heaoBCassiopeiaSupernovaRemnant.png",
  //         imageCredit: "",
  //   imageDesc:"",

  //   },
  //   {
  //     imageId: "hippaD",
  //     image: "/imageList/heaoB/heaoBNebulaAndromeda.png",
  //         imageCredit: "",
  //  imageDesc:"",

  //   },
  //   {
  //     imageId: "hippaE",
  //     image: "/imageList/heaoB/HeaoBCrabNebula.png",
  //       imageCredit: "",
  //   imageDesc:"",

  //   },
  //   {
  //     imageId: "hippaF",
  //     image: "/imageList/heaoB/HeaoBQuasar.png",
  //       imageCredit: "",
  //  imageDesc:"",

  //   },
  //   {
  //     imageId: "hippaG",
  //     image: "/imageList/heaoB/HeaoBTychosSupernova.png",
  //       imageCredit: "",
  //   imageDesc:"",

  //   },
  // ],
  recentNews: [
    {
      newsId: "",
      image: "",
      title: "",
      summary: "",
    },
  ],
};
