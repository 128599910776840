export const chandra = {
  id: "chandra",
  title: "Chandra",
  launchDate: "July 23, 1999",
  endDate: " Active",
  isFavorite: true,
  src: "/images/chandra.png",
  credit: "credit image",
  button: "Chandra",
  missionStatus: "active",
  missionType: "orbital",
  launchVehicle: "Columbia",
  launchSite: "	Kennedy Space Center, Fl",
  keyFact: [
    "Chandra is sensitive to X-ray sources 50-100 times fainter than any previous X-ray telescope",
    "The light from some of the quasars observed by Chandra will have been traveling through space for ten billion years.",
  ],
  desc: {
    descId: "chandraInfo",
    headline:
      "Chandra can observe x-rays from particles up to the last second before they fall into a black hole",
    mainDesc:
      "The Chandra X-ray Observatory  is part of NASA's ﬂeet of Great Observatories. It’s operating orbit takes it 200-times higher than the Hubble Space Telescope. During each 64-hour orbit of the Earth, Chandra travels one-third of the way to the Moon.",
    subDesc:
      "Currently the world's most powerful X-ray telescope, Chandra has eight-times greater resolution and is able to detect sources more than 50 times fainter than any previous X-ray telescope. Chandra allows scientists from around the world to obtain extraodinary X-ray images and spectra of violent, high-temperature events and objects to help us better understand the structure and evolution of our universe.",
  },

  images: [
    {
      imageId: "chandraA",
      image: "/imageList/chandra/planetWhirlpool.png",
      imageCredit:
        "X-ray: NASA/CXC/SAO/R. DiStefano, et al.; Optical: NASA/ESA/STScI/Grendler",
      imageDesc:
        "Composite image of M51 with X-rays from Chandra and optical light from NASA's Hubble Space Telescope",
    },
    {
      imageId: "chandraB",
      image: "/imageList/chandra/milkyWay.png",
      imageCredit: " NASA/CXC/UMass/Q.D. Wang",
      imageDesc: "Center of the Milky Way galaxy",
    },
    {
      imageId: "chandraC",
      image: "/imageList/chandra/westerlund2.png",
      imageCredit: "NASA/CXC/SAO/K.Arcand",
      imageDesc:
        "A cluster of young stars - about one to two million years old - located about 20,000 light years from Earth",
    },
    {
      imageId: "chandraD",
      image: "/imageList/chandra/galaxy3C31.png",
      imageCredit: "NASA/STScI",
      imageDesc:
        "Galaxy 3C31 , located 240 million light years from Earth jet blasting away from one side of the central galaxy, which also is known as NGC 383",
    },
    {
      imageId: "chandraE",
      image: "/imageList/chandra/abell665.png",
      imageCredit: "NASA/CXC/Univ. of Alabama/S.Dasadia et al",
      imageDesc:
        "This system, known as Abell 665, has an extremely powerful shockwave, second only to the famous Bullet Cluster",
    },
    {
      imageId: "chandraM",
      image: "/imageList/chandra/bulletCluster.png",
      imageCredit: "NASA/CXC/SAO/K.Arcand",
      imageDesc:
        " First direct proof of dark matter, the mysterious unseen substance that makes up the vast majority of matter in the Universe.",
    },
    {
      imageId: "chandraF",
      image: "/imageList/chandra/CTB37A.png",
      imageCredit: "NASA/CXC/Morehead State Univ/T.Pannuti et al",
      imageDesc:
        "Supernova remnant located in our Galaxy about 20,000 light years from Earth",
    },
    {
      imageId: "chandraG",
      image: "/imageList/chandra/toothbrush.png",
      imageCredit: "NASA/CXC/SAO/R. van Weeren et al",
      imageDesc: "Galaxy cluster about 2.7 billion light years from Earth",
    },
    {
      imageId: "chandraN",
      image: "/imageList/chandra/cygnusOB2.png",
      imageCredit:
        "X-ray: NASA/CXC/SAO/J. Drake et al; H-alpha: Univ. of Hertfordshire/INT/IPHAS; Infrared: NASA/JPL-Caltech/Spitzer",
      imageDesc: " Massive stars found in Cygnus OB2",
    },
    {
      imageId: "chandraH",
      image: "/imageList/chandra/NGC6357.png",
      imageCredit:
        "X-ray: NASA/CXC/PSU/L. Townsley et al; Optical: UKIRT; Infrared: NASA/JPL-Caltech",
      imageDesc:
        "Region called NGC 6357 where radiation from heat, containing at least three clusters of young stars is energizing the cooler gas in the cloud that surrounds them",
    },
    // {
    //   imageId: "chandraI",
    //   image: "/imageList/chandra/cometLeonard.png",
    //   imageCredit: "",
    //   imageDesc: "",
    // },
    {
      imageId: "chandraR",
      image: "/imageList/chandra/xrayMosaic.png",
      imageCredit: "NASA/UMass/D. Wang et al",
      imageDesc:
        "Mosaic of several Chandra images of the central region of our Milky Way galaxy",
    },
    {
      imageId: "chandraJ",
      image: "/imageList/chandra/cygnia.png",
      imageCredit: "NASA/CXC/U.Wisc-Madison/S. Heinz et al",
      imageDesc:
        "Set of rings around a black hole part of a binary system called V404 Cygni, located about 7,800 light-years away from Earth",
    },
    {
      imageId: "chandraK",
      image: "/imageList/chandra/sn1987A.png",
      imageCredit:
        "X-ray: NASA/CXC/PSU/S.Park & D.Burrows.; Optical: NASA/STScI/CfA/P.Challis",
      imageDesc:
        "Supernova from our neighboring galaxy the Large Magellanic Cloud",
    },
    // {
    //   imageId: "chandraQ",
    //   image: "/imageList/chandra/psrj1509.png",
    //   imageCredit: "",
    //   imageDesc: "",
    // },
    {
      imageId: "chandraL",
      image: "/imageList/chandra/crabNebula.png",
      imageCredit:
        "X-ray: NASA/CXC/SAO; Optical: NASA/STScI; Infrared: NASA/JPL/Caltech; Radio: NSF/NRAO/VLA; Ultraviolet: ESA/XMM-Newton",
      imageDesc:
        "View of the Crab Nebula which is the result of a supernova that astronomers observed here on Earth back in 1054",
    },
    {
      imageId: "chandraO",
      image: "/imageList/chandra/sagittariusA.png",
      imageCredit: "NASA/CXC/Univ. of Wisconsin/Y.Bai, et al",
      imageDesc: " Sagittarius A, the black hole at the center of our galaxy",
    },
    {
      imageId: "chandraP",
      image: "/imageList/chandra/westerlund2Xray.png",
      imageCredit: "NASA/STScI",
      imageDesc:
        "A cluster of young stars - about one to two million years old - located about 20,000 light years from Earth",
    },

    {
      imageId: "chandraQ",
      image: "/imageList/chandra/Abell370.png",
      imageCredit:
        "X-ray: NASA/CXC/Penn State Univ./G. Garmire; Optical: NASA/STScI",
      imageDesc:
        "About 4 billion light years from Earth, galaxy cluster Abell 370",
    },
  ],
  recentNews: [
    {
      newsId: "milkyWay",
      image: "/imageList/chandra/blackHoleCenter.png",
      imageCredit: "Event Horizon Telescope collaboration",
      month: "4",
      year: "2022",
      title: "Sagittarius A*, at the centre of the Milky Way",
      summary:
        "A trio of NASA telescopes, in conjunction with others on the ground, is helping astronomers learn more about the Milky Way's supermassive black hole. A celestial object that has the mass of 4 million suns but could fit comfortably within the orbit of Mercury, the closest planet to the sun.",
      link: "https://www.nasa.gov/mission_pages/chandra/images/sagittarius-a-nasa-telescopes-support-event-horizon-telescope-in-studying-milky-ways.html",
    },
  ],
};
