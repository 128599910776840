import { ThemeProvider } from "@mui/material/styles";
import { Route, Routes } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";

import "./App.css";
import theme from "./theme";
import { HomePage } from "./Pages/HomePage/Homepage.jsx";
import { Telescope } from "./Pages/Telescope/Telescope";
import { LinkList } from "./Pages/Resources/Resources";
import { FilterPagesLayout } from "./Pages/FilterPages/FilterPagesLayout";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Routes>
        <Route path="/telescopes" element={<FilterPagesLayout />} />
        <Route path="/news" element={<FilterPagesLayout isNews />} />
        <Route path="/telescope/:telescopeId" element={<Telescope />} />
        <Route path="/resources" element={<LinkList />} />
        <Route path="/" element={<HomePage />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
