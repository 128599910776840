
export const textBox = {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  };


  
 export const statusLabel = {
    textTransform: "uppercase",
    fontWeight: 800,
    color: "#c36503",
    letterSpacing: 1.5,
  };


 export const statusLabelFlex = {
    fontWeight: 800,
    letterSpacing: 0.5,
    lineHeight: 2,
    alignSelf: "center",
    alignItems: "baseline",
    flexGrow: 2,
    rowGap: 1,
  };

  export const gridLayout = {
    display: "flex",
    width: "100%",
    flexDirection: "row-reverse"
  };

  export const border = {
    border: "1px solid rgba(0, 0, 0, 0.12)"
  }