import CheckIcon from "@mui/icons-material/Check";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormGroup,
  FormLabel,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  ACTIVE,
  filteredTelescopesStates,
  filteredTelescopesTypes,
  FUTURE,
  INACTIVE,
} from "../../data/telescope";
import { StyledHeaderTitle } from "../styles/StylesCheckboxes";
import { CheckboxGroup } from "./CheckboxGroup";

export function MissionStatusFilter({ isSidebar }) {
  const [checkedStates, setCheckedStates] = useRecoilState(
    filteredTelescopesStates
  );

  const [checkedTypes, setCheckedTypes] = useRecoilState(
    filteredTelescopesTypes
  );

  const handleSelectAll = () => {
    setCheckedStates([]);
    setCheckedTypes([]);
  };

  const allSelected = !checkedStates.length && !checkedTypes.length;

  return (
    <>
      <Box display="flex" justifyContent="space-between" pt={1}>
        <StyledHeaderTitle variant="h6">Mission Status</StyledHeaderTitle>

        {!allSelected && (
          <Button
            variant="contained"
            startIcon={<CheckIcon />}
            sx={{ py: 1 }}
            onClick={handleSelectAll}
          >
            All
          </Button>
        )}
      </Box>
      <Box display="grid" py={1}>
        <Divider flexItem alignSelf="center" />
      </Box>
      <FormControl>
        <FormLabel>Status:</FormLabel>
        {!isSidebar ? (
          <FormGroup row>
            <CheckboxGroup
              options={[
                [ACTIVE, "Active"],
                [INACTIVE, "Inactive"],
                [FUTURE, "Planned"],
              ]}
              selectedValues={checkedStates}
              setValues={setCheckedStates}
            />
          </FormGroup>
        ) : (
          <FormGroup>
            <CheckboxGroup
              options={[
                [ACTIVE, "Active"],
                [INACTIVE, "Inactive"],
                [FUTURE, "Planned"],
              ]}
              selectedValues={checkedStates}
              setValues={setCheckedStates}
            />
          </FormGroup>
        )}
      </FormControl>
    </>
  );
}
