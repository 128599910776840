import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React from "react";
import { useRecoilState } from "recoil";
import {
  filteredTelescopesStates,
  filteredTelescopesTypes
} from "../../data/telescope";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export function ChipsArray() {
  const [checkedStates, setCheckedStates] = useRecoilState(
    filteredTelescopesStates
  );

  const [checkedTypes, setCheckedTypes] = useRecoilState(
    filteredTelescopesTypes
  );

  return (
    <Box display="flex"  sx={{ flexWrap:"wrap"}}  >
      {checkedStates.map((name) => (
        <>
          <Box
            sx={{
              listStyle: "none",
              p: 0.5,
              m: 0,
             
            }}
            component="ul"
          >
            <ListItem key={name} >
              <Chip
                label={name}
                name={name}
                onDelete={() =>
                  setCheckedStates(
                    checkedStates.filter((item) => item !== name)
                  )
                }
              />
            </ListItem>
          </Box>
        </>
      ))}

      {checkedTypes.map((name) => (
        <Box
          sx={{
            listStyle: "none",
            p: 0.5,
            m: 0,
          }}
          component="ul"
        >
          <ListItem key={name}>
            <Chip
              label={name}
              name={name}
              onDelete={() =>
                setCheckedTypes(checkedTypes.filter((item) => item !== name))
              }
            />
          </ListItem>
        </Box>
      ))}
    </Box>
  );
}
